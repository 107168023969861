import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@broadlume/willow-ui'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { FaChevronDown } from 'react-icons/fa'
import { queryClient } from '../context/constants'
import { useFetchPortalConfig } from '../pages/settings-customHook'
import { handleTabShow } from '../services/auth'
import { useAuthHook } from '../utils/hooks/auth.hooks'
import { Logo } from './logo'
import { PrimaryButton } from './primary-button'
const router = getRouteApi('/$clientid/_app')
const Header = ({
    isYardiSource,
    isPreview = false,
    logoUrl,
    placeOrderStyle,
    ...rest
}: {
    isYardiSource?: boolean
    isPreview?: boolean
    logoUrl?: string
    placeOrderStyle?: React.CSSProperties
} & React.HTMLAttributes<HTMLDivElement>) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()
    const { data: userData } = useAuthHook()
    const handleLogout = () => {
        localStorage.clear()
        queryClient.resetQueries()
        navigate({ to: '/$clientid/login', params: params })
    }
    const { data: portalConfigData } = useFetchPortalConfig()

    return (
        <div
            className="bg-willow-ash text-willow-wool-light top-0 custom-header-footer"
            {...rest}
        >
            <div className=" flex justify-between px-4 py-4 md:px-12">
                {/* Logo */}
                <div className="flex lg:flex-row max-lg:flex-col flex-wrap w-1/2 ">
                    <div className="max-w-40">
                        <Logo
                            className="max-h-12 hover:cursor-pointer"
                            key="header-logo"
                            logoUrl={logoUrl}
                            onClick={async () => {
                                if (isPreview) {
                                    return
                                }
                                if (portalConfigData?.LinkHome) {
                                    window.open(portalConfigData.LinkHome)
                                    return
                                }

                                if (!isYardiSource) {
                                    await navigate({
                                        to: '/$clientid',
                                        params: params,
                                    })
                                    location.reload()
                                }
                            }}
                        />
                    </div>
                    {!isPreview && (
                        <div className="flex flex-col custom-font-color lg:ml-4 max-lg:mt-2 max-lg:text-pretty">
                            <h2 className="block text-wool md:text-4xl text-3xl font-bold custom-font-color">
                                {portalConfigData?.TitleBar || ''}
                            </h2>
                            <h4 className="block">
                                {portalConfigData?.SubTitle || ''}
                            </h4>
                        </div>
                    )}
                </div>

                {/* Right side: Order Button and Dropdown */}
                <div className="flex gap-4 justify-center items-center ">
                    {!isYardiSource &&
                    handleTabShow({
                        userDetails: userData?.userDetails,
                        module: 'write:orders',
                    }) ? (
                        <>
                            {/* Button with full title for larger screens */}
                            <PrimaryButton
                                title="+ Place Order"
                                onClick={() => {
                                    if (!isPreview) {
                                        navigate({ to: '/$clientid/orders' })
                                        setTimeout(() => {
                                            navigate({
                                                to: '/$clientid/place-order',
                                            })
                                        }, 50)
                                    }
                                }}
                                className="hidden sm:block" // Show only on larger screens
                                style={placeOrderStyle}
                            />
                        </>
                    ) : null}

                    {/* User Dropdown */}
                    {!isYardiSource && !isPreview && (
                        <DropdownMenu>
                            <DropdownMenuTrigger className="flex flex-row items-center gap-2">
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger>
                                            {' '}
                                            <div className="bg-[hsl(var(--secondary))] text-[var(--secondary-text-color)] px-2 py-1.5 rounded-full">
                                                {userData?.userDetails?.DisplayName?.substring(
                                                    0,
                                                    2
                                                ).toUpperCase() ||
                                                    userData?.userDetails?.Email?.substring(
                                                        0,
                                                        2
                                                    ).toUpperCase()}
                                            </div>
                                        </TooltipTrigger>

                                        <TooltipContent className="bg-willow-wool-light rounded-lg p-2">
                                            <div>
                                                {
                                                    userData?.userDetails
                                                        ?.DisplayName
                                                }
                                            </div>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                                <FaChevronDown size={14} />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem onClick={handleLogout}>
                                    Logout
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Header
