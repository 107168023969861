import { useState } from 'react'
import { BroadlumeLogoSvg } from '../context/assets'
import { useGetClient } from '../utils/hooks/auth.hooks'

type ImageWrapperProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    logoUrl?: string
    secondaryFill?: string
}

export const Logo = (props: ImageWrapperProps) => {
    const { data: clientId } = useGetClient()
    const [showSvg, setShowSvg] = useState(false)
    const CLIENT_CUSTOM_LOGO =
        props?.logoUrl ??
        `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/data/images/logo.png`
    const handleFallbackError = (e) => {
        // e.target.src = '/broadlume-logo.png' // Set fallback image
        e.target.onerror = null // Prevent infinite loop
        // e.target.className = 'w-60 h-7'
        setShowSvg(true)
    }

    return !props?.logoUrl && showSvg ? (
        <BroadlumeLogoSvg
            className="hover:cursor-pointer"
            secondaryFill={props.secondaryFill}
            onClick={props?.onClick}
        />
    ) : (
        <img
            className="w-full h-auto aspect-auto"
            src={CLIENT_CUSTOM_LOGO}
            onError={handleFallbackError}
            alt="Company Logo"
            {...props}
        />
    )
}
