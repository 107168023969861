import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { LandingPage } from '../pages/landingpage'

import { CustomToast } from '../components/UI/toast'
import Footer from '../components/footer'
import { NoClientIdFound } from '../components/no-client-id-found'
import { NoPropertiesFound } from '../components/no-properties-found'
import { ServerError } from '../components/server-error'
import { useGetClient } from '../utils/hooks/auth.hooks'

export const Route = createRootRouteWithContext<{
    queryClient: QueryClient
}>()({
    component: () => {
        return (
            <div className="flex flex-col min-h-screen">
                <CustomHelmet />

                <div className="flex flex-grow overflow-auto pb-0">
                    <Outlet />
                </div>

                <Footer />
                <div className="sm:hidden flex-grow overflow-auto pt-20"></div>
                <CustomToast />
            </div>
        )
    },
    notFoundComponent() {
        return <NoClientIdFound />
    },
    errorComponent({ error }) {
        if (['No locations found'].includes(error.message)) {
            return (
                <div className="flex flex-col min-h-screen">
                    <CustomHelmet />
                    <div className="flex flex-grow overflow-auto pb-0">
                        <LandingPage>
                            <NoPropertiesFound />
                        </LandingPage>
                    </div>
                    <Footer />
                    <div className="sm:hidden flex-grow overflow-auto pt-20"></div>
                </div>
            )
        }

        if (
            [
                'Something went wrong. Server is not reachable at the moment. Please try again later',
            ].includes(error.message)
        ) {
            return (
                <div className="flex flex-col min-h-screen">
                    <CustomHelmet />
                    <div className="flex flex-grow overflow-auto pb-0">
                        <LandingPage>
                            <ServerError />
                        </LandingPage>
                    </div>
                    <Footer />
                    <div className="sm:hidden flex-grow overflow-auto pt-20"></div>
                </div>
            )
        }

        if (error.message.includes('Could not find an active match')) {
            return (
                <div className="flex flex-col min-h-screen">
                    <NoClientIdFound />
                </div>
            )
        }

        return (
            <div>
                An error occurred. Please try again later or contact support.
            </div>
        )
    },
})

const CustomHelmet = () => {
    const { data: clientId } = useGetClient()
    // TODO: Change these according to the environment
    const CLIENT_CUSTOM_CSS_URL = `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/css/custom-theme.css?v=${new Date().getTime()}`
    const CLIENT_CUSTOM_LOGO = `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/data/images/logo.png`

    return (
        <HelmetProvider>
            <Helmet>
                <link
                    rel="icon"
                    type="image/svg+xml"
                    href={CLIENT_CUSTOM_LOGO}
                />
                <link href={CLIENT_CUSTOM_CSS_URL} rel="stylesheet"></link>
            </Helmet>
        </HelmetProvider>
    )
}
