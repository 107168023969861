import { useNavigate } from '@tanstack/react-router'
import clsx from 'classnames'
import { useFetchCustomTheme } from '../pages/settings-customHook'
import { PrimaryButton } from './primary-button'
import { handleTabShow } from '../services/auth'
import { useAuthHook } from '../utils/hooks/auth.hooks'
type Props = { title: string; className?: string } & {
    otherProps?: {
        wrapperProps?: React.HTMLAttributes<HTMLDivElement>
        titleProps?: React.HTMLAttributes<HTMLHeadingElement>
        previewTitleContainerProps?: React.HTMLAttributes<HTMLDivElement>
    }
}
/**
 * A functional component that wraps its children within a styled container.
 *
 * @param {React.PropsWithChildren<Props>} props - The props containing children elements to be rendered inside the wrapper.
 * @returns {JSX.Element} A JSX element containing a header and the provided children.
 */
export const PageWrapper = ({
    title,
    children,
    className = '',
    otherProps = {
        titleProps: {},
        wrapperProps: {},
        previewTitleContainerProps: {},
    },
}: React.PropsWithChildren<Props>) => {
    const navigate = useNavigate({ from: '/' })
    const { data: themeConfig } = useFetchCustomTheme()
    const { data: userData } = useAuthHook()

    return (
        <div
            className={clsx(
                'flex flex-col items-start justify-start flex-1 py-6 px-4 w-full',
                className
            )}
            {...otherProps.wrapperProps}
        >
            {title && (
                <div
                    className={clsx(
                        'flex items-center justify-between w-full mb-3',
                        otherProps.previewTitleContainerProps?.className
                    )}
                >
                    <h2
                        className={clsx(
                            'text-wool md:text-4xl text-3xl font-bold custom-font-color'
                        )}
                        style={{
                            color: themeConfig?.visibleTextColor,
                        }}
                        {...otherProps.titleProps}
                    >
                        {title}
                    </h2>
                    {handleTabShow({
                        userDetails: userData?.userDetails,
                        module: 'write:orders',
                    }) && (
                        <PrimaryButton
                            title="+ Place Order"
                            onClick={() => {
                                navigate({ to: '/$clientid/orders' })
                                setTimeout(() => {
                                    navigate({
                                        to: '/$clientid/place-order',
                                    })
                                }, 50)
                            }}
                            className="flex sm:hidden"
                        />
                    )}
                </div>
            )}
            {children}
        </div>
    )
}
