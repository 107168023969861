import {
    Card,
    CardContent,
    CardFooter,
    DatePicker,
    Label,
    Separator,
    toast,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { format, parse } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { CardContainer, CardRow } from '../../../components/CardContainer'
import { CustomInput } from '../../../components/custom-input'
import { CustomDropDown } from '../../../components/CustomSelect'
import { InputField } from '../../../components/inputField'
import LoadingComponent from '../../../components/LoadingComponent'
import { PageWrapper } from '../../../components/PageWrapper'
import { OrderItems } from '../../../components/place-order/order-items'
import { TreeNode } from '../../../components/place-order/tree-view'
import { PrimaryButton } from '../../../components/primary-button'
import { SecondaryButton } from '../../../components/secondary-button'
import { SelectWrapper } from '../../../components/SelectWrapper'
import {
    createOrder,
    createOrderHeader,
    createYardiOrderSummary,
    getLabor,
    getMaterial,
} from '../../../services/placeOrder'
import {
    convertDateYYYYMMDD,
    generateTransactionID,
    groupByDeep,
    isMobileOrTablet,
} from '../../../utils'
import { occupiedIndicesOptions } from '../../../utils/constants'
import { useAuthHook } from '../../../utils/hooks/auth.hooks'
import { useGetCustomerPordFile } from '../../../utils/hooks/customer.hook'
import {
    AccordianMaterialList,
    GetCustomerAPIObject,
    GetLaborAPIResponse,
    GetPOrdAPIObject,
    ShipToUnitTypeDropDown,
} from '../../../utils/interfaces/place-order.interface'
import {
    placeOrderFilterValidation,
    placeOrderFormValidation,
} from '../../../utils/validators/schemas'
import {
    useFetchCustomTheme,
    useFetchPortalConfig,
} from '../../settings-customHook'
import {
    useGetAllEdocs,
    useGetEdocPdf,
    useGetSendToAddress,
    useSendEmail,
} from './hooks'
import { useSentryQuery } from '../../../utils/useSentryQuery'
import clsx from 'classnames'

const today = new Date()
// today.setDate(today.getDate() - 1)

// // OrderCutOffTime
// const ORDER_CUT_OFF_TIME = '12pm' // CST Time?

// // OrderCutOffMessage
// const ORDER_CUT_OFF_MESSAGE = '* Next day installation requests are not guaranteed until confirmed by AFB. Request cut off time is 4 pm.  *'

// // BlockWeekends
// const BLOCK_WEEKENDS = true

// // Blocked dates
// const BLOCKED_DATES = []

// // DisablePlaceOrderAMPM, by default enabled
// const DISABLE_PLACE_ORDER_AMPM = true

// // Occupied, value: 1,2,3,4,5
// // Question for Broadlume: What does 0 mean?
// const OCCUPIED = [1,2]

// Need to Put validation based on the Address API response :
// If dtAddressAll.Rows(rIndex).Item("CADD_WEB_PO") = "Y" Then ViewState("PORequired") = True : rfvPONumber.Enabled = True : lblRequiredPO.Visible = True Else rfvPONumber.Enabled = False : lblRequiredPO.Visible = False
// If dtAddressAll.Rows(rIndex).Item("CADD_WEB_BUILDING") = "Y" Then ViewState("BuildingRequired") = True : rfvBuilding.Enabled = True : lblRequiredBuilding.Visible = True Else rfvBuilding.Enabled = False : lblRequiredBuilding.Visible = False
// If dtAddressAll.Rows(rIndex).Item("CADD_WEB_UNIT") = "Y" Then ViewState("UnitRequired") = True : rfvUnit.Enabled = True : lblRequiredUnit.Visible = True Else rfvUnit.Enabled = False : lblRequiredUnit.Visible = False

// Need to set defailt value based on the Address API response :
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_CONTACT") Then lblContact.Text = dtAddressAll.Rows(rIndex).Item("CADD_CONTACT")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_ADDR1") Then lblAddress1.Text = dtAddressAll.Rows(rIndex).Item("CADD_ADDR1") : txbInstallAddress.Text = dtAddressAll.Rows(rIndex).Item("CADD_ADDR1")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_ADDR2") Then lblAddress2.Text = dtAddressAll.Rows(rIndex).Item("CADD_ADDR2")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_CTYST") Then lblCityState.Text = dtAddressAll.Rows(rIndex).Item("CADD_CTYST")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_ZIPCD") Then lblZip.Text = dtAddressAll.Rows(rIndex).Item("CADD_ZIPCD")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_PHONE1") Then lblPhone1.Text = dtAddressAll.Rows(rIndex).Item("CADD_PHONE1")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_PHONE2") Then lblPhone2.Text = dtAddressAll.Rows(rIndex).Item("CADD_PHONE2")
// If Not dtAddressAll.Rows(rIndex).IsNull("CADD_FAX") Then lblFax.Text = dtAddressAll.Rows(rIndex).Item("CADD_FAX")

// Call the New API for Ship to address details and set on UI
// Dim dtTable As DataTable = CGIRequests.GetSendToAddress(Key, dtAddressAll.Rows(rIndex).Item("CADD_COID"), dtAddressAll.Rows(rIndex).Item("CADD_CUST"),
//                                                          dtAddressAll.Rows(rIndex).Item("PDMO_SHCODE"), "", "")
//  If dtTable IsNot Nothing Then
//      CleanDataTable(dtTable)
//      lblContactB.Text = dtTable.Rows(0).Item("CONTACT")
//      lblAddressB1.Text = dtTable.Rows(0).Item("ADDR1")
//      lblAddressB2.Text = dtTable.Rows(0).Item("ADDR2")
//      lblCityStateB.Text = dtTable.Rows(0).Item("CTYST")
//      lblZipB.Text = dtTable.Rows(0).Item("ZIPCD")
//      lblPhoneB1.Text = dtTable.Rows(0).Item("PHONE1")
//      lblPhoneB2.Text = dtTable.Rows(0).Item("PHONE2")
//      lblFaxB.Text = dtTable.Rows(0).Item("FAX")
//  End If

// Put validation for 0 rows for Unit type selection (If CGI call return 0 rows for that selected unit).
// MESSAGE: There are no valid lines for this unit type. Please select another type.

// EMAIL API Integration
// curl --request POST \
//   --url http://localhost:3000/dev/triggerEmail \
//   --header 'Authorization: Bearer eyJraWQiO' \
//   --header 'Content-Type: application/json' \
//   --header 'User-Agent: insomnia/10.1.1' \
//   --data '{
// 	"emailType": "place-order",
// 	"title": "OrderPlaced!",
// 	"heading": "OrderPlaced!",
// 	"customerName": "Dhaval Javia",
// 	"referenceNumber": "123456",
// 	"shipToAddress": "1234, 5thAvenue, NewYork, NY10001",
// 	"building": "Building1",
// 	"unit": "Unit1",
// 	"placedBy": "DhavalJavia",
// 	"email": "dhavalj@york.ie",
// 	"unitType": "UnitType1",
// 	"poNumber": "PO123456",
// 	"installDate": "2022-01-01",
// 	"moveInDate": "2022-01-01",
// 	"customerServicePhone": "+124122322342",
// 	"lines": [
// 		"'\'''\''              > ACTIVESPIRIT BIRCHPAPER       > REPLACE ALL",
// 		"'\'''\''              > ACTIVESPIRIT BIRCHPAPER       > LIVING ROOM",
// 		"'\'''\''              > ACTIVESPIRIT BIRCHPAPER       > DINING ROOM",
// 		"CARPET          > ACCELERATOR MELTEDCHOCOLATE   > REPLACE ALL",
// 		"CARPET          > ACCELERATOR MELTEDCHOCOLATE   > LIVING ROOM",
// 		"CARPET          > ACCELERATOR MELTEDCHOCOLATE   > BEDROOM",
// 		"CARPET          > ACCELERATOR MELTEDCHOCOLATE   > HALL",
// 		"REPLACE PAD     > STANDARDPLUS 6LBCARPETPAD ",
// 		"VINYL           > AIRSTEPBASIS 33912            > KITCHEN",
// 		"Labor           > WARRANTYWORK"
// 	]
// }'

const route = getRouteApi('/$clientid/_app/place-order')
const PlaceOrder = () => {
    const { data: userData } = useAuthHook()
    const { data: portalConfigData } = useFetchPortalConfig()
    const sendEmail = useSendEmail()
    const queryParams = route.useSearch()
    const navigate = route.useNavigate()
    const params = route.useParams()
    let yardiSource = false

    if (queryParams && queryParams.source) {
        yardiSource = queryParams.source === 'yardi'
    }

    const [laborMaterialFetchParams, setLaborMaterialFetchParams] = useState<{
        customer: string
        property: string
        shipTo: string
    }>({
        customer: '',
        property: '',
        shipTo: '',
    })
    const [customer, setCustomer] = useState<GetCustomerAPIObject>()
    const [property, setProperty] = useState<GetPOrdAPIObject>()
    const [errorMessage, setErrorMessage] = useState(null)
    const {
        customerData,
        isCustomerLoading,
        isPordFileLoading,
        pordFileData,
        shipToItems,
    } = useGetCustomerPordFile()

    const [selectedNodes, setSelectedNodes] = useState<TreeNode[]>([]) // Track selected nodes
    const { data: themeConfig } = useFetchCustomTheme()
    // get ship to and unittype data form
    const findingForm = useForm({
        onSubmit: async ({ value }) => {
            const customer = customerData?.find(
                (customer) => customer.C_CID === value.shipTo
            )

            const trimmedUnitType = value.unitType.split('___')[1]
            const property = pordFileData?.find(
                (property) => property.PDMO_PROPTYPE === trimmedUnitType
            )

            if (property) {
                setProperty(property)
            }
            if (customer) {
                setCustomer(customer)
            }
            setLaborMaterialFetchParams({
                customer: findingForm?.state?.values?.shipTo ?? '',
                property:
                    findingForm.state.values.unitType.split('___')[1] ?? '',
                shipTo: findingForm.state.values.unitType.split('___')[0] ?? '',
            })
        },
        validators: {
            onChange: placeOrderFilterValidation,
        },
        validatorAdapter: zodValidator(),
        defaultValues: {
            shipTo: '',
            unitType: '',
        },
    })

    const shipToField = findingForm.useField({
        name: 'shipTo',
    })

    const unitTypeField = findingForm.useField({
        name: 'unitType',
    })

    const { data: sendToAddressData } = useGetSendToAddress({
        customer: laborMaterialFetchParams.customer,
        shipTo: laborMaterialFetchParams.shipTo,
    })

    console.log('sendToAddressData', sendToAddressData)

    const [orderItemsRequired, setOrderItemsRequired] = useState(false)
    const { isPoRequired, isBuildingRequired, isUnitRequired } = useMemo(() => {
        const selectedShipTo = shipToItems.find(
            (item) => item.C_CID === shipToField.state.value
        )
        return {
            isPoRequired: selectedShipTo?.CADD_WEB_PO === 'Y',
            isBuildingRequired: selectedShipTo?.CADD_WEB_BUILDING === 'Y',
            isUnitRequired: selectedShipTo?.CADD_WEB_UNIT === 'Y',
        }
    }, [shipToField.state.value, shipToItems])

    const formatLines = (nodes: TreeNode[]): string[] => {
        console.log('nodes', nodes)
        const formattedLines: string[] = []

        const traverse = (node: TreeNode, parentLabel: string) => {
            const label = parentLabel
                ? `${parentLabel} > ${node.name}`
                : node.name
            if (node.children && node.children.length > 0) {
                node.children.forEach((child) => traverse(child, label))
            } else {
                formattedLines.push(label)
            }
        }

        nodes.forEach((node) => traverse(node, ''))
        return formattedLines
    }

    const form = useForm({
        onSubmit: async ({ value }) => {
            const shipTo = findingForm.getFieldValue('shipTo')
            const unitType = findingForm.getFieldValue('unitType')
            const unitTypeSplit = unitType.split('___')

            const transaction = generateTransactionID()
            const header = createOrderHeader({
                poNo: value.poNo,
                occupied: value.occupied,
                email: value.email!,
                buildingNumber: value.buildingNumber,
                unitNumber: value.unitNumber,
                streetAddress: value.streetAddress,
                onsiteContact: value.onsiteContact,
            })
            if (value?.items?.length === 0) {
                setOrderItemsRequired(true)
                return
            } else {
                setOrderItemsRequired(false)
            }
            // set lines andd comments
            // const items = Object.keys(value.items!)
            const lines: string[] = []
            const comments: string[] = []
            const itemSeparator = '/*\\'
            const commentSeparator = '//**\\'
            value.items.map((item: string) => {
                const [val1, val2] = item.split('___')
                if (!lines.includes(val1)) {
                    lines.push(val1)
                } else {
                    // create object with key name and pass array string with value.
                    comments.push(val1 + itemSeparator + val2)
                }
            })

            // make notes
            const notes: string[] = []

            // if moveInDate is there
            if (value.moveInDate) {
                const subNote: string[] = [
                    '0',
                    'M',
                    convertDateYYYYMMDD(value.moveInDate),
                    'MOVE IN DATE',
                ]
                notes.push(subNote.join(itemSeparator))
            }

            // if requestedInstallDate is there
            if (value.requestedInstallDate) {
                const subNote: string[] = [
                    '1',
                    'I',
                    convertDateYYYYMMDD(value.requestedInstallDate),
                ]
                if (value.onsiteContactInfo) {
                    subNote.push(value.onsiteContactInfo.toUpperCase())
                }
                // if (value.anyTime !== 'anyTime') {
                //     if (value.anyTime === 'am'){
                //         subNote.push('+')
                //     }

                //     if (value.anyTime === 'pm'){
                //         subNote.push('-')
                //     }
                // }
                notes.push(subNote.join(itemSeparator))
            }

            // if generalComment is there
            if (value.generalComment) {
                const subNote: string[] = [
                    '5',
                    'G',
                    convertDateYYYYMMDD(new Date()),
                    value.generalComment.toUpperCase(),
                ]
                notes.push(subNote.join(itemSeparator))
            }

            // const comments = value.generalComment
            await createOrder({
                property: unitTypeSplit[1],
                customer: shipTo,
                shipTo: unitTypeSplit[0],
                transaction,
                header,
                lines: lines.join(itemSeparator),
                notes: comments.join(commentSeparator),
                comments: notes.join(commentSeparator),
                ...(yardiSource ? { pmsoft: 'yardi' } : {}),
            })

            // If Yardi source then create cXML file
            if (yardiSource) {
                const result = await createYardiOrderSummary({
                    buyerCookie: 'abc123xyz',
                    fromIdentity: 'buyer@example.com',
                    toIdentity: 'supplier@example.com',
                    senderIdentity: 'marketplace@example.com',
                    userAgent: 'Mozilla/5.0',
                    total: 150.75,
                    shipping: 10.5,
                    tax: 5.25,
                    buyerEndpointUrl: 'https://reqres.in/api/users',
                    items: [
                        // TODO: Fix these lines to be dynamic lines
                        {
                            quantity: 2,
                            supplierPartID: 'SP-1001',
                            supplierPartAuxiliaryID: 'AUX-2001',
                            unitPrice: 70.0,
                            description: 'Wireless Mouse',
                            unitOfMeasure: 'Each',
                            classification: '43211708',
                        },
                        {
                            quantity: 1,
                            supplierPartID: 'SP-1002',
                            supplierPartAuxiliaryID: 'AUX-2002',
                            unitPrice: 30.25,
                            description: 'USB Keyboard',
                            unitOfMeasure: 'Each',
                            classification: '43211706',
                        },
                    ],
                })
                toast({
                    title: 'Order',
                    description: result,
                    variant: 'success',
                })
                // localStorage.clear()
                // navigate({ to: '/$clientid/login', params: params })
            }

            const formattedLines = formatLines(selectedNodes)

            await sendEmail.mutateAsync({
                emailType: 'place-order',
                title: 'Order Placed!',
                heading: 'Order Placed!',
                customerName: value.orderedBy,
                referenceNumber: transaction,
                shipToAddress: value.streetAddress,
                building: value.buildingNumber,
                unit: value.unitNumber,
                placedBy: value.orderedBy,
                email: value.email,
                unitType: unitTypeSplit[1],
                poNumber: value.poNo,
                installDate: format(value.requestedInstallDate, 'MM/dd/yyyy'),
                moveInDate: value.moveInDate
                    ? format(value.moveInDate, 'MM/dd/yyyy')
                    : '-',
                customerServicePhone: '+124122322342',
                lines: formattedLines,
            })
            toast({
                title: 'Order',
                description: 'Order placed successfully!',
                variant: 'success',
            })

            form.reset()
            if (userData?.userDetails.access.includes('read:orders')) {
                await navigate({
                    to: '/$clientid/orders',
                    params: params,
                })
            }
        },
        validatorAdapter: zodValidator(),
        validators: {
            onChange: placeOrderFormValidation({
                isPoRequired,
                isBuildingRequired,
                isUnitRequired,
            }),
            onMount: placeOrderFormValidation({
                isPoRequired,
                isBuildingRequired,
                isUnitRequired,
            }),
        },
        defaultValues: {
            orderedBy: userData?.userDetails?.DisplayName,
            email: userData?.userDetails?.Email,
            poNo: '',
            occupied: '',
            moveInDate: undefined,
            anyTime: 'am',
            requestedInstallDate: undefined,
            onsiteContact: '',
            onsiteContactInfo: '',
            streetAddress: customer?.C_ADDR1 || '',
            buildingNumber: '',
            unitNumber: '',
            generalComment: '',
            style: '',
            color: '',
            items: undefined,
            edocSeq: undefined,
        },
    })

    const occupiedField = form.useField({
        name: 'occupied',
    })

    const moveInDateField = form.useField({
        name: 'moveInDate',
    })

    const anyTimeField = form.useField({
        name: 'anyTime',
    })

    const itemsField = form.useField({
        name: 'items',
    })

    const orderedByField = form.useField({
        name: 'orderedBy',
    })

    const emailField = form.useField({
        name: 'email',
    })

    const poNoField = form.useField({
        name: 'poNo',
    })

    const requestedInstallDateField = form.useField({
        name: 'requestedInstallDate',
    })

    const onsiteContactField = form.useField({
        name: 'onsiteContact',
    })

    const onsiteContactInfoField = form.useField({
        name: 'onsiteContactInfo',
    })

    const streetAddressField = form.useField({
        name: 'streetAddress',
    })

    const buildingNumberField = form.useField({
        name: 'buildingNumber',
    })

    const unitNumberField = form.useField({
        name: 'unitNumber',
    })

    const generalCommentField = form.useField({
        name: 'generalComment',
    })

    const edocSeqField = form.useField({
        name: 'edocSeq',
    })

    const { data: edocsList } = useGetAllEdocs({
        ...laborMaterialFetchParams,
    })

    const { data: edocData, isLoading: isEdocLoading } = useGetEdocPdf({
        ...laborMaterialFetchParams,
        BASENAME: edocsList?.find(
            (item) => item.SEQ === edocSeqField.state.value
        )?.BASENAME,
        SEQ: edocSeqField.state.value,
    })

    useEffect(() => {
        if (edocData?.status !== 200) {
            return
        }

        fetch(
            edocData.data.includes(
                `data:${edocData.headers.get('Content-Type')};`
            )
                ? edocData.data
                : `data:${edocData.headers.get('Content-Type')};base64,${edocData.data}`
        )
            .then((res) => res.blob())
            .then((blob) => {
                console.log(blob)
                // window.open(URL.createObjectURL(edocData))
                const file = new Blob([blob], {
                    type:
                        edocData.headers.get('Content-Type') ||
                        'application/pdf',
                })
                const fileURL = URL.createObjectURL(file)

                window.open(fileURL, '_blank')
            })
    }, [edocData?.data])

    const {
        data: labors,
        isLoading: isLaborLoading,
        isFetching: isLaborFetching,
    } = useSentryQuery({
        queryKey: ['getLabor', laborMaterialFetchParams, userData],
        queryFn: async ({ signal }) => {
            const data = await getLabor({ ...laborMaterialFetchParams, signal })
            return createLaborNodeList(data)
        },
        enabled: Boolean(
            laborMaterialFetchParams.customer &&
                laborMaterialFetchParams.property &&
                laborMaterialFetchParams.shipTo
        ),
        staleTime: Infinity,
    })

    const {
        data: materials,
        isLoading: isMaterialLoading,
        isFetching: isMaterialFetching,
    } = useSentryQuery({
        queryKey: ['getMaterial', laborMaterialFetchParams],
        queryFn: async ({ signal }) => {
            try {
                const data = await getMaterial({
                    ...laborMaterialFetchParams,
                    signal,
                })
                const groupedMaterial = groupByDeep(data, [
                    'WEBNAME',
                    'REFSTYLE',
                ]) as AccordianMaterialList
                return createMaterialNodeList(groupedMaterial)
            } catch (error) {
                console.error(error)
                return {}
            }
        },
        enabled: Boolean(
            laborMaterialFetchParams.customer &&
                laborMaterialFetchParams.property &&
                laborMaterialFetchParams.shipTo
        ),
        staleTime: Infinity,
    })

    const createMaterialNodeList = (collection: AccordianMaterialList) => {
        const nodeList: TreeNode = {
            id: 'materials',
            value: 'materials',
            name: '',
            children: [],
        }
        if (!Object.keys(collection).length) {
            setErrorMessage(
                'There are no valid lines for this unit type. Please select another type.'
            )
            return
        } else {
            setErrorMessage(null)
        }
        Object.entries(collection).forEach(([key, material]) => {
            const node: TreeNode = {
                name: key,
                id: key,
                value: key,
                children: [],
            }
            Object.keys(material).map((matKey, index) => {
                const materialStyle = material[matKey]
                if (materialStyle[index]) {
                    const subNode: TreeNode = {
                        name: `${matKey} ${material[matKey][0].REFCOLOR}`,
                        id: Number(materialStyle[index].ITEMLINE).toString(),
                        value: Number(materialStyle[index].ITEMLINE).toString(),
                        children: [],
                    }
                    if (materialStyle[index]?.WEBNAMECOMMENT?.length !== 0) {
                        materialStyle.map((mat, index) => {
                            subNode?.children?.push({
                                name: mat.WEBNAMECOMMENT.trim(),
                                id: `${Number(mat.ITEMLINE)}___${index + 1}`,
                                value: `${Number(mat.ITEMLINE)}___${index + 1}`,
                                children: [],
                            })
                        })
                    } else {
                        subNode.name =
                            matKey + ' ' + materialStyle[index]?.REFCOLOR
                        subNode.id = `${Number(materialStyle[index]?.ITEMLINE)}___${index + 1}`
                    }
                    node?.children?.push(subNode)
                } else {
                    const subNode: TreeNode = {
                        name: `${matKey} ${materialStyle[0].REFCOLOR}`,
                        id: Number(materialStyle[0].ITEMLINE).toString(),
                        value: Number(materialStyle[0].ITEMLINE).toString(),
                        children: [],
                    }
                    node?.children?.push(subNode)
                }
            })
            nodeList?.children?.push(node)
        })
        return nodeList
    }

    const createLaborNodeList = (collection: GetLaborAPIResponse) => {
        if (collection?.length === 0) {
            return {}
        }
        const data = collection[0]
        return {
            name: 'LABOR',
            id: 'LABOR',
            value: 'LABOR',
            children: [
                {
                    name: data.WEBNAME.trim(),
                    id: `${Number(data.ITEMLINE)}___0`,
                    value: `${Number(data.ITEMLINE)}___0`,
                    children: [],
                },
            ],
        }
    }

    const unitTypeList = useMemo(() => {
        if (pordFileData?.length) {
            return pordFileData
                .map((unitType) => {
                    if (shipToField.state.value === '') {
                        return {
                            label: `${unitType.PDMH_WEBNAME || unitType.PDMO_PROPTYPE}`,
                            value:
                                unitType.PDMO_SHCODE +
                                '___' +
                                unitType.PDMO_PROPTYPE,
                        }
                    } else if (
                        unitType.PDMO_CUSTID === shipToField.state.value
                    ) {
                        return {
                            label: `${unitType.PDMH_WEBNAME || unitType.PDMO_PROPTYPE}`,
                            value:
                                unitType.PDMO_SHCODE +
                                '___' +
                                unitType.PDMO_PROPTYPE,
                        }
                    }
                    return null
                })
                .filter((val) => val) as unknown as ShipToUnitTypeDropDown[]
        }
        return []
    }, [pordFileData, shipToField.state.value])

    console.log('itemsField.state.value', itemsField.state.value, {
        labors,
        materials,
        selectedNodes,
    })

    const isBtnDisabled =
        !(!findingForm.state.isPristine && findingForm.state.isValid) ||
        findingForm.state.isSubmitting ||
        isCustomerLoading ||
        isPordFileLoading ||
        isLaborLoading ||
        isMaterialLoading ||
        isLaborFetching ||
        isMaterialFetching

    const isBtnLoading =
        isCustomerLoading ||
        isPordFileLoading ||
        isLaborLoading ||
        isMaterialLoading ||
        isLaborFetching ||
        isMaterialFetching
    // const isDropDownLoading =
    //     isPordFileLoading ||
    //     isCustomerLoading ||
    //     findingForm.state.isSubmitting ||
    //     isLaborLoading ||
    //     isMaterialLoading ||
    //     isLaborFetching ||
    //     isMaterialFetching

    console.log('form.state.isSubmitting', form.state)

    return (
        <PageWrapper title="Place Order">
            {/* OrderCutOffTime would be in format of 5:00 pm */}
            {portalConfigData?.OrderCutOffTime &&
                parse(
                    portalConfigData?.OrderCutOffTime,
                    'h:mm aa',
                    new Date()
                ) < new Date() &&
                portalConfigData?.OrderCutOffMessage && (
                    <span className="text-sm text-willow-danger my-2 text-pretty">
                        {portalConfigData?.OrderCutOffMessage}
                    </span>
                )}
            <div className="flex flex-col md:flex-row justify-between sm:gap-5 w-full overflow-hidden">
                <div className="flex-1">
                    {/* <CustomDropDown
                        label="Customer"
                        placeholder="Select Customer..."
                        options={shipToItems}
                        isSearchable={true}
                        value={shipToField.state?.value}
                        isLoading={isDropDownLoading}
                        isDisabled={isDropDownLoading}
                        onChange={(option) => {
                            shipToField.setValue(option)
                        }}
                        onBlur={shipToField.handleBlur}
                        errorProps={{
                            errors: shipToField.state.meta.errors,
                        }}
                        classNames={{
                            label: ['text-sm font-normal font-sans'],
                        }}
                        styles={{
                            control: (base) => ({
                                ...base,
                                minHeight: '25px',
                                fontSize: '12px',
                            }),
                            placeholder: (base) => ({
                                ...base,
                                fontSize: '12px',
                            }),
                        }}
                    /> */}
                    <SelectWrapper
                        data={shipToItems}
                        label="Ship To"
                        classNames={{
                            label: ['text-sm font-normal font-sans'],
                        }}
                        placeholder={'Select Ship To'}
                        otherProps={{
                            selectRootProps: {
                                onValueChange: shipToField.handleChange,
                                value: shipToField.state.value || undefined,
                                disabled:
                                    isPordFileLoading ||
                                    isCustomerLoading ||
                                    findingForm.state.isSubmitting ||
                                    isLaborLoading ||
                                    isMaterialLoading ||
                                    isLaborFetching ||
                                    isMaterialFetching,
                                onOpenChange: shipToField.handleBlur,
                            },
                            selectContentProps: {
                                className: 'text-sm font-sans font-normal',
                            },
                            selectTriggerProps: {
                                className: `text-sm font-sans font-normal focus:!ring-custom-primary_font ${shipToField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                            },
                            labelProps: {
                                style: {
                                    color: themeConfig?.visibleTextColor,
                                },
                            },
                        }}
                    />
                </div>
                <div className="flex-1 max-md:flex-none max-md:flex max-md:items-end max-md:justify-between max-md:gap-6 md:flex-[0.5] mt-2 md:mt-0">
                    <SelectWrapper
                        data={unitTypeList}
                        label="Unit Type"
                        classNames={{
                            label: ['text-sm font-normal font-sans'],
                            root: [
                                'max-md:mt-2 max-md:flex max-md:flex-col max-md:flex-[2]',
                            ],
                        }}
                        placeholder={'Select unit type'}
                        otherProps={{
                            selectRootProps: {
                                onValueChange: unitTypeField.handleChange,
                                value: unitTypeField.state.value || undefined,
                                disabled:
                                    isPordFileLoading ||
                                    isCustomerLoading ||
                                    findingForm.state.isSubmitting ||
                                    isLaborLoading ||
                                    isMaterialLoading ||
                                    isLaborFetching ||
                                    isMaterialFetching,
                                onOpenChange: () => {
                                    unitTypeField.handleBlur()
                                },
                            },
                            selectContentProps: {
                                className: 'text-sm font-sans font-normal',
                            },
                            selectTriggerProps: {
                                className: clsx(
                                    'text-sm font-sans font-normal focus:!ring-custom-primary_font max-md:p-[18px]',
                                    {
                                        'border-willow-heart':
                                            unitTypeField.state.meta.isTouched,
                                    }
                                ),
                            },
                            labelProps: {
                                style: {
                                    color: themeConfig?.visibleTextColor,
                                },
                            },
                        }}
                    />
                    <PrimaryButton
                        type="submit"
                        title="Search"
                        className="hidden max-md:flex max-md:flex-[0]"
                        isLoading={isBtnLoading}
                        disabled={isBtnDisabled}
                        onClick={() => {
                            findingForm.handleSubmit()
                            form.reset()
                        }}
                    />
                </div>
                <div className="max-md:hidden w-full md:w-auto place-content-end text-center mt-2 md:mt-0">
                    <PrimaryButton
                        type="submit"
                        title="Search"
                        isLoading={isBtnLoading}
                        disabled={isBtnDisabled}
                        onClick={() => {
                            findingForm.handleSubmit()
                            form.reset()
                        }}
                    />
                </div>
            </div>
            {!errorMessage && edocsList?.length ? (
                <CardRow className="flex flex-row md:w-full lg:w-1/2 items-start justify-between gap-4 w-fit mt-3">
                    <CustomDropDown
                        label="Edocs"
                        placeholder="Select Edoc"
                        options={edocsList}
                        getOptionLabel={(item) => item.DESC}
                        getOptionValue={(item) => item.SEQ}
                        classNames={{
                            label: ['text-sm mb-0'],
                        }}
                        onChange={(value) => {
                            edocSeqField.setValue(value.SEQ)
                        }}
                        onBlur={edocSeqField.handleBlur}
                        value={edocsList.find(
                            (item) => item.SEQ === edocSeqField.state.value
                        )}
                        isLoading={isEdocLoading}
                    />
                </CardRow>
            ) : null}
            {!isMobileOrTablet && (isCustomerLoading || isPordFileLoading) && (
                <LoadingComponent classNames="absolute top-[50%] left-[50%] w-6 h-6 mt-10 m-auto" />
            )}
            {!isLaborLoading &&
            !isMaterialLoading &&
            findingForm.state.isSubmitted ? (
                errorMessage ? (
                    <NoUnitTypeMessageBox errorMessage={errorMessage} />
                ) : (
                    <div className="grid grid-cols-1 mt-2 w-full">
                        <Card>
                            <CardContent className="md:p-6 sm:p-4 p-3">
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-2 md:divide-x divide-x-0 md:divide-y-0 divide-y !divide-[#EDEDED]">
                                    <div>
                                        <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                            Ship To
                                        </h1>
                                        <div className="grid grid-cols-2 sm:gap-4 gap-3">
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal break-words">
                                                Location:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CID +
                                                        ' - ' +
                                                        customer?.C_NAME}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal break-words">
                                                Zip:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ZIP}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Contact:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CONTACT}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Phone 1:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Address:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR1}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Phone 2:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Building:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Fax:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_FAX}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                City / State:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CITY +
                                                        ', ' +
                                                        customer?.C_STATE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Property Type:{' '}
                                                <span className="font-normal">
                                                    {property?.PDMH_WEBNAME ||
                                                        property?.PDMO_PROPTYPE}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:ps-8 md:pt-0 sm:pt-5 pt-2">
                                        <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                            Bill To
                                        </h1>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Contact:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CONTACT}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Zip:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ZIP}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Address:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR1}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Phone 1:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Building:{' '}
                                                <span className="font-normal"></span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Phone 2:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                City / State:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CITY +
                                                        ', ' +
                                                        customer?.C_STATE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans whitespace-normal  break-words">
                                                Fax:{' '}
                                                <span className="font-normal"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Separator className="md:my-10 sm:my-5 my-3" />
                                <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Order Header
                                </h1>
                                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="orderedBy"
                                                label="Ordered By"
                                                labelClassName="my-2"
                                                required
                                                className={`text-sm font-sans !font-normal mt-2 focus:!ring-custom-primary_font ${orderedByField.state.meta.isTouched ? '' : 'border-willow-heart'}`}
                                                value={
                                                    orderedByField.state.value
                                                }
                                                onBlur={
                                                    orderedByField.handleBlur
                                                }
                                                onChange={(e) => {
                                                    orderedByField.handleChange(
                                                        e.target.value
                                                    )
                                                }}
                                            ></InputField>
                                            <>
                                                {
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            orderedByField.state
                                                                .meta.errors
                                                        }
                                                    </span>
                                                }
                                                {orderedByField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="email"
                                                label="Email "
                                                labelClassName="my-2"
                                                required
                                                className={`text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font ${emailField.state.meta.isTouched ? '' : 'border-willow-heart'}`}
                                                value={emailField.state.value}
                                                onBlur={emailField.handleBlur}
                                                onChange={(e) =>
                                                    emailField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {emailField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            emailField.state
                                                                .meta.errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {emailField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={poNoField.name}
                                            name={poNoField.name}
                                            value={poNoField.state.value}
                                            onChange={(e) =>
                                                poNoField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            required={isPoRequired}
                                            isTouched={
                                                poNoField.state.meta.isTouched
                                            }
                                            errors={poNoField.state.meta.errors}
                                            onBlur={poNoField.handleBlur}
                                            label={'P.O. Number'}
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <SelectWrapper
                                            data={occupiedIndicesOptions.filter(
                                                (item) =>
                                                    portalConfigData?.OccupiedIndices?.includes(
                                                        item.value
                                                    )
                                            )}
                                            label="Occupied"
                                            required={true}
                                            classNames={{
                                                label: [
                                                    'text-xs font-normal font-sans',
                                                ],
                                            }}
                                            otherProps={{
                                                selectRootProps: {
                                                    onValueChange:
                                                        occupiedField.handleChange,
                                                    value:
                                                        occupiedField.state
                                                            .value || undefined,
                                                    onOpenChange:
                                                        occupiedField.handleBlur,
                                                },
                                                selectContentProps: {
                                                    className:
                                                        'text-sm font-sans font-normal',
                                                },
                                                selectTriggerProps: {
                                                    className: `mt-2 text-sm font-sans font-normal focus:!ring-custom-primary_font ${occupiedField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                                },
                                            }}
                                        />
                                        <>
                                            {occupiedField.state.meta
                                                .isTouched ? (
                                                <span className="text-xs text-willow-danger">
                                                    {
                                                        occupiedField.state.meta
                                                            .errors
                                                    }
                                                </span>
                                            ) : null}
                                            {occupiedField.state.meta
                                                .isValidating
                                                ? 'Validating...'
                                                : null}
                                        </>
                                    </div>
                                    <div className="font-normal text-xs flex-1 font-sans">
                                        <>
                                            <Label htmlFor="moveInDate">
                                                Move In Date (If Applicable)
                                            </Label>
                                            <div className="flex gap-2">
                                                <DatePicker
                                                    id="moveInDate"
                                                    disabledDates={(date) =>
                                                        today > date
                                                    }
                                                    selected={
                                                        moveInDateField.state
                                                            .value
                                                    }
                                                    onDayBlur={
                                                        moveInDateField.handleBlur
                                                    }
                                                    onDayClick={
                                                        moveInDateField.handleChange
                                                    }
                                                    className="capitalize rounded w-full text-sm h-auto p-1 px-2 pr-0 font-sans font-normal mt-2 focus:!ring-custom-primary_font flex-row-reverse justify-between basis-96 "
                                                />
                                                {portalConfigData?.DisablePlaceOrderAMPM && (
                                                    <SelectWrapper
                                                        data={[
                                                            {
                                                                id: 'am',
                                                                value: 'am',
                                                                label: 'AM',
                                                            },
                                                            {
                                                                id: 'pm',
                                                                value: 'pm',
                                                                label: 'PM',
                                                            },
                                                        ]}
                                                        placeholder="AM/PM"
                                                        otherProps={{
                                                            selectRootProps: {
                                                                onValueChange:
                                                                    anyTimeField.handleChange,
                                                                value:
                                                                    anyTimeField
                                                                        .state
                                                                        .value ||
                                                                    undefined,
                                                                onOpenChange:
                                                                    anyTimeField.handleBlur,
                                                            },
                                                            selectContentProps:
                                                                {
                                                                    className:
                                                                        'text-sm font-sans font-normal',
                                                                },
                                                            selectTriggerProps:
                                                                {
                                                                    className: `mt-2 text-sm font-sans font-normal focus:!ring-custom-primary_font ${anyTimeField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                                                },
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <>
                                                {moveInDateField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            moveInDateField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {moveInDateField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal flex flex-col text-xs font-sans">
                                        <>
                                            <Label
                                                htmlFor="requestedInstallDate"
                                                className=""
                                            >
                                                Requested Install Date
                                                <span className="text-willow-heart">
                                                    *
                                                </span>
                                            </Label>
                                            <DatePicker
                                                id="requestedInstallDate"
                                                selected={
                                                    requestedInstallDateField
                                                        .state.value
                                                }
                                                onDayBlur={
                                                    requestedInstallDateField.handleBlur
                                                }
                                                onDayClick={
                                                    requestedInstallDateField.handleChange
                                                }
                                                disabledDates={[
                                                    { before: new Date() },
                                                    {
                                                        dayOfWeek:
                                                            portalConfigData?.BlockWeekends
                                                                ? [0, 6]
                                                                : [],
                                                    },
                                                    ...(portalConfigData?.BlockedDates?.map(
                                                        (eachDate) =>
                                                            parse(
                                                                eachDate,
                                                                'MM-dd-yyyy',
                                                                new Date()
                                                            )
                                                    ) || []),
                                                    portalConfigData?.OrderCutOffTime &&
                                                    parse(
                                                        portalConfigData?.OrderCutOffTime,
                                                        'h:mm aa',
                                                        new Date()
                                                    ) < new Date()
                                                        ? new Date()
                                                        : undefined,
                                                ]}
                                                className="capitalize rounded w-full text-sm h-auto p-1 px-2 pr-0 font-sans font-normal mt-2 focus:!ring-custom-primary_font flex-row-reverse justify-between "
                                            />
                                            <>
                                                {requestedInstallDateField.state
                                                    .meta.isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            requestedInstallDateField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {requestedInstallDateField.state
                                                    .meta.isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={onsiteContactField.name}
                                            name={onsiteContactField.name}
                                            value={
                                                onsiteContactField.state.value
                                            }
                                            onChange={(e) =>
                                                onsiteContactField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                onsiteContactField.state.meta
                                                    .isTouched
                                            }
                                            errors={
                                                onsiteContactField.state.meta
                                                    .errors
                                            }
                                            onBlur={
                                                onsiteContactField.handleBlur
                                            }
                                            label={'Onsite Contact'}
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={onsiteContactInfoField.name}
                                            name={onsiteContactInfoField.name}
                                            value={
                                                onsiteContactInfoField.state
                                                    .value
                                            }
                                            onChange={(e) =>
                                                onsiteContactInfoField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                onsiteContactInfoField.state
                                                    .meta.isTouched
                                            }
                                            errors={
                                                onsiteContactInfoField.state
                                                    .meta.errors
                                            }
                                            onBlur={
                                                onsiteContactInfoField.handleBlur
                                            }
                                            label={'Onsite Contact Info'}
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                </div>
                                <h1 className="font-sans md:text-xl sm:text-lg mt-6 text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Ship To
                                </h1>
                                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={streetAddressField.name}
                                            name={streetAddressField.name}
                                            value={
                                                streetAddressField.state.value
                                            }
                                            onChange={(e) =>
                                                streetAddressField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                streetAddressField.state.meta
                                                    .isTouched
                                            }
                                            errors={
                                                streetAddressField.state.meta
                                                    .errors
                                            }
                                            onBlur={
                                                streetAddressField.handleBlur
                                            }
                                            label={
                                                'Street Address (If Different)'
                                            }
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={buildingNumberField.name}
                                            name={buildingNumberField.name}
                                            value={
                                                buildingNumberField.state.value
                                            }
                                            required={isBuildingRequired}
                                            onChange={(e) =>
                                                buildingNumberField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                buildingNumberField.state.meta
                                                    .isTouched
                                            }
                                            errors={
                                                buildingNumberField.state.meta
                                                    .errors
                                            }
                                            onBlur={
                                                buildingNumberField.handleBlur
                                            }
                                            label={'Building Number'}
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <CustomInput
                                            id={unitNumberField.name}
                                            name={unitNumberField.name}
                                            required={isUnitRequired}
                                            value={unitNumberField.state.value}
                                            onChange={(e) =>
                                                unitNumberField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                unitNumberField.state.meta
                                                    .isTouched
                                            }
                                            errors={
                                                unitNumberField.state.meta
                                                    .errors
                                            }
                                            onBlur={unitNumberField.handleBlur}
                                            label={'Unit Number'}
                                            type={'text'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                    <div className="font-normal text-xs font-sans md:col-span-3 sm:col-span-2 col-span-1">
                                        <CustomInput
                                            id={generalCommentField.name}
                                            name={generalCommentField.name}
                                            value={
                                                generalCommentField.state.value
                                            }
                                            onChange={(e) =>
                                                generalCommentField.setValue(
                                                    e.target.value
                                                )
                                            }
                                            isTouched={
                                                generalCommentField.state.meta
                                                    .isTouched
                                            }
                                            errors={
                                                generalCommentField.state.meta
                                                    .errors
                                            }
                                            onBlur={
                                                generalCommentField.handleBlur
                                            }
                                            label={'General Comment'}
                                            type={'textarea'}
                                            classNames={{
                                                root: ['my-4'],
                                            }}
                                        />
                                    </div>
                                </div>
                                <Separator className="md:my-10 sm:my-5 my-3" />
                                <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Items in Order
                                </h1>
                                <div className="flex bg-willow-ash-lightest rounded-xl whitespace-normal">
                                    {!isLaborLoading && !isMaterialLoading ? (
                                        <OrderItems
                                            labors={labors!}
                                            materials={materials!}
                                            onItemsSelected={
                                                itemsField.setValue
                                            }
                                            setSelectedNodes={setSelectedNodes}
                                            selected={itemsField.state.value}
                                        />
                                    ) : null}
                                </div>
                                <>
                                    {orderItemsRequired ? (
                                        <span className="text-xs text-willow-danger">
                                            Order Items is required
                                        </span>
                                    ) : null}
                                </>
                            </CardContent>
                            <CardFooter className="grid grid-cols-1 sm:text-end text-center">
                                <div className="flex justify-end gap-6">
                                    <SecondaryButton
                                        title="Cancel"
                                        onClick={() => {
                                            form.reset()
                                        }}
                                    />
                                    <PrimaryButton
                                        type="submit"
                                        title="Submit"
                                        isLoading={form.state.isSubmitting}
                                        disabled={
                                            !(
                                                !form.state.isPristine &&
                                                form.state.isValid
                                            ) || form.state.isSubmitting
                                        }
                                        onClick={() => {
                                            form.handleSubmit()
                                        }}
                                    />
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                )
            ) : null}
        </PageWrapper>
    )
}

export default PlaceOrder

const NoUnitTypeMessageBox = ({ errorMessage }: { errorMessage: string }) => {
    return (
        <CardContainer className="mt-2 w-full items-center">
            <div className="text-pretty">{errorMessage}</div>
        </CardContainer>
    )
}
