import { UserDetails } from '../utils/interfaces/user.interface'
import { axiosInstance, verifier } from './api'

export const signIn = async ({
    email,
    password,
    clientId,
    session,
    otp,
    username,
}:
    | {
          email: string
          password: string
          clientId: string
      }
    | { session: string; otp: string; username: string }) => {
    try {
        const response = await axiosInstance.post(
            '/login',
            session
                ? { session, otp, username }
                : {
                      email,
                      password: password,
                      module: clientId,
                  }
        )
        await setAndVerifyToken({
            IdToken: response.data.tokens.IdToken,
            AccessToken: response.data.tokens.AccessToken,
            RefreshToken: response.data.tokens.RefreshToken,
        })
        return response
    } catch (error) {
        console.error('Error signing in: ', error)
        throw error
    }
}

export const setAndVerifyToken = async ({
    IdToken,
    AccessToken,
    RefreshToken,
}: {
    IdToken: string
    AccessToken: string
    RefreshToken: string
}) => {
    await verifier.hydrate()

    const payload = await verifier.verify(AccessToken!)

    localStorage.setItem('idToken', IdToken || '')
    localStorage.setItem('accessToken', AccessToken || '')
    localStorage.setItem('refreshToken', RefreshToken || '')
    const metaData = JSON.parse(payload['custom:metadata']) as UserDetails
    /**
     * 1. Check if space is there or not in property
     * 2. replace || and /*\ separators with /*\ and comma(,) respectively
     */
    // metaData.Properties = metaData?.Properties?.replace(' ', '')
    // if (newPropertyRegex.test(metaData?.Properties)) {
    //     // true -> /*\\ is separator between items and , is separator between item label and value.
    //     metaData.Properties = metaData?.Properties.replaceAll(
    //         '/*\\',
    //         '||'
    //     ).replaceAll(',', '/*\\')
    // }
    // if (PropertyRegex.test(metaData?.Properties)) {
    //     // true -> || is separator between items and /*\ is separator between item label and value.
    //     metaData.Properties = metaData?.Properties.split('||')
    //         .map((item) => item.replace(/\/\*\\/g, ','))
    //         .join('/*\\')
    // }
    metaData.Branches = metaData?.Branches?.replace(/\|\|$/g, '')
    metaData.Branches = metaData?.Branches?.replace(/\|\|/g, '/*\\')
    localStorage.setItem('userDetails', JSON.stringify(metaData))
    axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + AccessToken
    authQueryFn(true)
}

export const authQueryFn = (updateAxios?: boolean) => {
    const accessToken = localStorage.getItem('accessToken')
    const idToken = localStorage.getItem('idToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const userDetailsString = localStorage.getItem('userDetails')!
    const userDetails = JSON.parse(userDetailsString) as UserDetails
    if (updateAxios && userDetails?.api_config) {
        axiosInstance.defaults.headers['Authorization'] =
            'Bearer ' + accessToken
    }
    return { accessToken, idToken, refreshToken, userDetails }
}

export const handleTabShow = ({
    userDetails,
    module,
}: {
    userDetails?: UserDetails
    module: string
}) => {
    return userDetails?.access?.includes(module)
}

export const getTabs = ({ userDetails }: { userDetails?: UserDetails }) => {
    if (!userDetails) {
        return []
    }
    const allTabs = [
        {
            id: 'dashboard',
            access: 'read:dashboard',
            img: '/svg/dashboard.svg',
            label: 'DASHBOARD',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'read:dashboard' }),
        },
        {
            id: 'orders',
            access: 'read:orders',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'read:orders' }),
            img: '/svg/cart.svg',
            label: 'ORDERS',
        },
        {
            id: 'invoices',
            access: 'invoices',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'invoices' }),
            img: '/svg/file.svg',
            label: 'INVOICES',
        },
        {
            id: 'order-analysis',
            access: 'read:sales-analysis',
            isTab: true,
            isActive: handleTabShow({
                userDetails,
                module: 'read:sales-analysis',
            }),
            img: '/svg/analysis.svg',
            label: 'ORDER ANALYSIS',
        },
        {
            id: 'user-admin',
            access: 'user:admin',
            isTab: true,
            isActive:
                handleTabShow({ userDetails, module: 'user:admin' }) ||
                handleTabShow({ userDetails, module: 'user:super-admin' }),
            img: '/svg/users.svg',
            label: 'USER ADMIN',
        },
        {
            id: 'settings',
            access: 'user:admin',
            isTab: true,
            isActive:
                handleTabShow({ userDetails, module: 'user:admin' }) ||
                handleTabShow({ userDetails, module: 'user:super-admin' }),
            img: '/svg/settings.svg',
            label: 'Settings',
        },
        {
            id: 'place-order',
            access: 'write:orders',
            isTab: false,
            isActive: handleTabShow({ userDetails, module: 'write:orders' }),
            img: '',
            label: 'Place Order',
        },
    ]

    return allTabs.filter((tab) => tab.isActive)
}
