import { getReadableFontColor } from '../utils'
import { useGetClient } from '../utils/hooks/auth.hooks'
import { useSentryQuery } from '../utils/useSentryQuery'
import { PmsConfig, themeSchemaValidator } from '../utils/validators/schemas'

export const useFetchCustomTheme = () => {
    const { data: clientId } = useGetClient()
    return useSentryQuery<themeSchemaValidator | null>({
        queryKey: ['theme', 'userData', clientId],
        enabled: Boolean(clientId),
        queryFn: async ({ signal }) => {
            try {
                const response = await fetch(
                    `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/data/custom-theme.json`,
                    { cache: 'no-cache', signal }
                )
                const data = (await response.json()) as themeSchemaValidator
                data['visibleTextColor'] = getReadableFontColor(
                    data.bgColor,
                    data.fontColor
                )
                return data
            } catch (error) {
                console.error('error themeing', error)
                return null
            }
        },
        staleTime: Infinity,
    })
}

export const useFetchPortalConfig = () => {
    const { data: clientId } = useGetClient()
    return useSentryQuery<PmsConfig | null>({
        queryKey: ['portalConfig', clientId],
        enabled: Boolean(clientId),
        queryFn: async ({ signal }) => {
            try {
                //TODO: Change
                const response = await fetch(
                    `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/data/custom-config.json`,
                    { cache: 'no-cache', signal }
                )
                return (await response.json()) as PmsConfig
            } catch (error) {
                console.error('error portalconfig settings', error)
                return {}
            }
        },
        staleTime: Infinity,
    })
}

export const useFetchClientCss = async () => {
    const { data: clientId } = useGetClient()
    return useSentryQuery<PmsConfig | null>({
        queryKey: ['portalConfig', clientId],
        enabled: Boolean(clientId),
        queryFn: async ({ signal }) => {
            try {
                const CLIENT_CUSTOM_CSS_URL = `${window.location.origin === 'http://localhost:5173' ? 'https://dev.customerportal.bms.my.broadlume.com' : window.location.origin}/${clientId}/css/custom-theme.css`

                const response = await fetch(CLIENT_CUSTOM_CSS_URL, {
                    cache: 'no-cache',
                    signal,
                })
                return (await response.text()) as PmsConfig
            } catch (error) {
                console.error('error theme css', error)
                return {}
            }
        },
        staleTime: Infinity,
    })
}
