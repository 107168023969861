import React, { useState, useEffect } from 'react'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { Checkbox, Label } from '@broadlume/willow-ui'
import clsx from 'classnames'

export type TreeNode = {
    id: string
    name: string
    value: string
    children?: TreeNode[]
}

type TreeViewProps = {
    data: TreeNode[]
    onChange?: (selectedValues: string[]) => void
    selectedValues?: string[]
    setSelectedNodes?: (nodes: TreeNode[]) => void
}

type CheckedState = Record<string, { checked: boolean; indeterminate: boolean }>

const TreeView: React.FC<TreeViewProps> = ({
    data,
    onChange,
    selectedValues,
    setSelectedNodes,
}) => {
    const [checkedState, setCheckedState] = useState<CheckedState>({})
    const [expandedState, setExpandedState] = useState<Record<string, boolean>>(
        {}
    ) // Track expanded state
    const [groupedSelectedNodes, setGroupedSelectedNodes] = useState<
        Record<string, TreeNode[]>
    >({}) // Track grouped selected nodes

    useEffect(() => {
        if (selectedValues && selectedValues.length > 0) {
            const updatedCheckedState = initializeCheckedState(
                data,
                selectedValues
            )
            setCheckedState(updatedCheckedState)
        } else {
            const initializeState = (nodes: TreeNode[]): CheckedState => {
                const state: CheckedState = {}
                const traverse = (node: TreeNode) => {
                    state[node.id] = { checked: false, indeterminate: false }
                    if (node.children) {
                        node.children.forEach(traverse)
                    }
                }
                nodes.forEach(traverse)
                return state
            }

            const initializeExpandedState = (
                nodes: TreeNode[]
            ): Record<string, boolean> => {
                const state: Record<string, boolean> = {}
                const traverse = (node: TreeNode) => {
                    state[node.id] = true // Default to expanded
                    if (node.children) {
                        node.children.forEach(traverse)
                    }
                }
                nodes.forEach(traverse)
                return state
            }

            setCheckedState(initializeState(data))
            setExpandedState(initializeExpandedState(data))
        }
    }, [selectedValues, data])

    const initializeCheckedState = (
        nodes: TreeNode[],
        selectedValues: string[]
    ): CheckedState => {
        const state: CheckedState = {}

        const traverse = (node: TreeNode) => {
            if (node.children && node.children.length > 0) {
                // Recursively initialize children's state
                node.children.forEach(traverse)

                // Determine the parent's checked state based on its children
                const allChildrenChecked = node.children.every(
                    (child) => state[child.id]?.checked
                )
                const someChildrenChecked = node.children.some(
                    (child) =>
                        state[child.id]?.checked ||
                        state[child.id]?.indeterminate
                )

                state[node.id] = {
                    checked: allChildrenChecked,
                    indeterminate: !allChildrenChecked && someChildrenChecked,
                }
            } else {
                // For leaf nodes, set checked based on `selectedValues`
                state[node.id] = {
                    checked: selectedValues.includes(node.value),
                    indeterminate: false,
                }
            }
        }

        nodes.forEach(traverse)
        return state
    }

    const handleCheckboxChange = (id: string) => {
        const updatedCheckedState = { ...checkedState }
        const isChecked = !checkedState[id]?.checked

        const updateChildren = (nodeId: string, checked: boolean) => {
            updatedCheckedState[nodeId] = { checked, indeterminate: false }
            const node = findNodeById(data, nodeId)
            if (node?.children) {
                node.children.forEach((child) =>
                    updateChildren(child.id, checked)
                )
            }
        }

        const updateParents = (nodeId: string) => {
            const parentNode = findParentNode(data, nodeId)
            if (parentNode) {
                const allChildren = parentNode.children || []
                const allChecked = allChildren.every(
                    (child) => updatedCheckedState[child.id]?.checked
                )
                const someChecked = allChildren.some(
                    (child) =>
                        updatedCheckedState[child.id]?.checked ||
                        updatedCheckedState[child.id]?.indeterminate
                )

                updatedCheckedState[parentNode.id] = {
                    checked: allChecked,
                    indeterminate: !allChecked && someChecked,
                }
                updateParents(parentNode.id)
            }
        }

        updateChildren(id, isChecked)
        updateParents(id)
        setCheckedState(updatedCheckedState)

        // Calculate selected values and pass to parent
        const selectedValues = getSelectedChildrenValues(
            data,
            updatedCheckedState
        )
        onChange && onChange(selectedValues)

        // Update selected nodes
        const selectedNodes = getSelectedNodes(data, updatedCheckedState)
        setSelectedNodes && setSelectedNodes(selectedNodes)

        // Group selected nodes by their parents
        const groupedNodes = groupSelectedNodesByParents(
            data,
            updatedCheckedState
        )
        setGroupedSelectedNodes(groupedNodes)
    }

    const getSelectedChildrenValues = (
        nodes: TreeNode[],
        checkedState: CheckedState
    ): string[] => {
        let selectedValues: string[] = []
        nodes.forEach((node) => {
            if (node.children && node.children.length > 0) {
                selectedValues = selectedValues.concat(
                    getSelectedChildrenValues(node.children, checkedState)
                )
            } else if (checkedState[node.id]?.checked) {
                selectedValues.push(node.value)
            }
        })
        return selectedValues
    }

    const getSelectedNodes = (
        nodes: TreeNode[],
        checkedState: CheckedState
    ): TreeNode[] => {
        let selectedNodes: TreeNode[] = []
        nodes.forEach((node) => {
            if (node.children && node.children.length > 0) {
                selectedNodes = selectedNodes.concat(
                    getSelectedNodes(node.children, checkedState)
                )
            } else if (checkedState[node.id]?.checked) {
                selectedNodes.push(node)
            }
        })
        return selectedNodes
    }

    const groupSelectedNodesByParents = (
        nodes: TreeNode[],
        checkedState: CheckedState
    ): Record<string, TreeNode[]> => {
        const groupedNodes: Record<string, TreeNode[]> = {}

        const traverse = (node: TreeNode, parent: TreeNode | null) => {
            if (
                checkedState[node.id]?.checked ||
                checkedState[node.id]?.indeterminate
            ) {
                const parentId = parent ? parent.id : 'root'
                if (!groupedNodes[parentId]) {
                    groupedNodes[parentId] = []
                }
                groupedNodes[parentId].push({
                    ...node,
                    children: node.children?.filter(
                        (child) =>
                            checkedState[child.id]?.checked ||
                            checkedState[child.id]?.indeterminate
                    ),
                })
            }
            if (node.children) {
                node.children.forEach((child) => traverse(child, node))
            }
        }

        nodes.forEach((node) => traverse(node, null))
        return groupedNodes
    }

    const findParentNode = (
        nodes: TreeNode[],
        id: string,
        parent: TreeNode | null = null
    ): TreeNode | null => {
        for (const node of nodes) {
            if (node.id === id) return parent
            if (node.children) {
                const parentNode = findParentNode(node.children, id, node)
                if (parentNode) return parentNode
            }
        }
        return null
    }

    const findNodeById = (nodes: TreeNode[], id: string): TreeNode | null => {
        for (const node of nodes) {
            if (node.id === id) return node
            if (node.children) {
                const found = findNodeById(node.children, id)
                if (found) return found
            }
        }
        return null
    }

    const toggleExpand = (id: string) => {
        setExpandedState((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }))
    }

    const renderTree = (nodes: TreeNode[]) => (
        <ul className="ml-4">
            {nodes.map((node) => (
                <li key={node.id} data-testid={'node-li-' + node.id}>
                    <div
                        className={clsx('flex items-center gap-2 mt-3 mb-2', {
                            'mt-6': node.children && node.children.length > 0,
                        })}
                    >
                        {/* Space reserved for Chevron, even if the node doesn't have one */}
                        <span className="w-4 flex-shrink-0">
                            {node.children && node.children.length > 0 && (
                                <span
                                    onClick={() => toggleExpand(node.id)}
                                    className="cursor-pointer"
                                >
                                    {expandedState[node.id] ? (
                                        <FaChevronDown />
                                    ) : (
                                        <FaChevronRight />
                                    )}
                                </span>
                            )}
                        </span>

                        <Checkbox
                            data-testid={'checkbox-' + node.id}
                            {...(checkedState[node.id]?.indeterminate
                                ? { checked: 'indeterminate' }
                                : checkedState[node.id]?.checked
                                  ? { checked: true }
                                  : { checked: false })}
                            onClick={() => handleCheckboxChange(node.id)}
                        />

                        <Label data-testid={'label-' + node.id}>
                            {node.name}
                        </Label>
                    </div>
                    {/* Children are indented */}
                    {node.children &&
                        expandedState[node.id] &&
                        renderTree(node.children)}
                </li>
            ))}
        </ul>
    )

    const getGroupedSelectedNodes = () => {
        const items = []
        const nonNumericRegex = new RegExp(/^$|[a-zA-Z]+/g)
        for (const key in groupedSelectedNodes) {
            console.log(key)
            if (nonNumericRegex.test(key) && key !== 'root') {
                const node = groupedSelectedNodes[key]
                // console.log({
                //     node,
                //     key
                // })
                let line = ``
                const lines: string[] = []
                node.map((n) => {
                    n?.children?.map((c) => {
                        line = `${key}  >  ${n.name}  >  ${c.name}`
                        lines.push(line)
                    })
                })
                items.push(...lines)
            }
        }
        console.log('groupedSelectedNodes', groupedSelectedNodes)
        console.log('groupedSelectedNodesItems', items)
        return groupedSelectedNodes
    }

    return (
        <div>
            {renderTree(data)}
            {false && (
                <div>
                    <h3>Selected Nodes Grouped by Parents:</h3>
                    <ul>
                        {Object.entries(getGroupedSelectedNodes()).map(
                            ([parentId, nodes]) => (
                                <li key={parentId}>
                                    <strong>
                                        {parentId === 'root'
                                            ? 'Root'
                                            : findNodeById(data, parentId)
                                                  ?.name}
                                    </strong>
                                    <ul>
                                        {nodes.map((node) => (
                                            <li key={node.id}>{node.name}</li>
                                        ))}
                                    </ul>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export { TreeView }
