/**
 * Renders error messages based on validation errors and touch status.
 *
 * @param {Object} props - The component props.
 * @param {ValidationError[]} [props.errors=[]] - An array of validation errors.
 * @param {boolean} [props.isTouched] - Indicates if the input has been touched.
 * @param {React.ComponentType} [props.customErrorComponent] - A custom component to render errors.
 * @returns {JSX.Element | null} - A list of error messages or a custom error component, or null if no errors are present.
 */ import { ValidationError } from '@tanstack/react-form'
import { CustomInput } from './custom-input'

export const ErrorComponent = ({
    errors = [],
    isTouched,
    customErrorComponent: CustomErrorComponent,
}: {
    errors?: ValidationError[]
    isTouched?: boolean
} & Pick<Parameters<typeof CustomInput>[0], 'customErrorComponent'>) => {
    const errorList = (errors && (errors[0] as string))?.split(',') || []
    if (!(isTouched && errorList?.length)) {
        return null
    }
    if (CustomErrorComponent) {
        return <CustomErrorComponent errorList={errorList} />
    }
    return errorList.map((error) => {
        return (
            <div className="text-willow-danger text-sm mt-1 block text-pretty">
                {error}
            </div>
        )
    })
}
