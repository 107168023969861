export const BroadlumeLogoSvg = ({
    primaryFill = '#e2ac43',
    secondaryFill = '#fff',
    width = '100%',
    height = '100%',
    ...otherProps
}: {
    primaryFill: string
    secondaryFill: string
} & React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1012 255.18"
        width={width}
        height={height}
        {...otherProps}
    >
        <g id="Text">
            <g>
                <path
                    style={{ fill: secondaryFill }}
                    d="M588.87,196.61l14.37,34.56h-5.05l-4.17-10.15h-15.74l-4.06,10.15h-4.66l14.1-34.56h5.21ZM579.76,217.24h12.73l-6.31-15.36h-.22l-6.2,15.36Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M632.33,194.91c10.73,0,16.55,6.78,17.17,15.7l-8.19.34c-.51-4.52-3.11-8.47-9.04-8.47s-9.21,4.24-9.21,10.9,3.5,10.9,9.21,10.9,8.53-3.95,9.04-8.47l8.19.34c-.62,8.92-6.44,15.7-17.17,15.7s-18.07-7.51-18.07-18.47,7.79-18.47,18.07-18.47Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M656.93,240.32l3.67-8.92-10.28-25.3h8.59l6.04,15.65h.22l6.16-15.65h8.02l-14.4,34.23h-8.02Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M689.24,231.17h-8.13v-25.08h8.13v3.9c1.36-2.77,4.41-4.58,8.19-4.58,6.78,0,9.04,4.69,9.04,10.05v15.7h-8.13v-13.73c0-3.61-1.19-5.59-4.18-5.59-3.28,0-4.91,2.6-4.91,6.55v12.77Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M722.82,205.42c7.45,0,11.63,4.91,12.26,10.9l-6.78.51c-.56-3.39-2.37-5.2-5.42-5.2s-5.36,2.2-5.36,7,2.2,7,5.36,7,4.86-1.86,5.42-5.25l6.78.56c-.62,6.04-4.8,10.9-12.26,10.9-7.85,0-13.56-5.25-13.56-13.22s5.7-13.22,13.56-13.22Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M746.23,195.59v35.58h-8.13v-35.58h8.13Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M754.55,240.32l3.67-8.92-10.28-25.3h8.59l6.04,15.65h.22l6.16-15.65h8.02l-14.4,34.23h-8.02Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M804.7,195.96c9.87,0,15.47,6.09,16.13,14.98l-4.61.22c-.38-6.36-4.39-11.14-11.52-11.14s-12.07,5.38-12.07,13.88,5.05,13.88,12.07,13.88,11.14-4.77,11.52-11.14l4.61.22c-.66,8.89-6.25,14.98-16.13,14.98s-16.95-7.3-16.95-17.94,7.41-17.94,16.95-17.94Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M835.68,231.83c-7.02,0-12.18-5.16-12.18-12.62s5.16-12.67,12.18-12.67,12.29,5.21,12.29,12.67-5.21,12.62-12.29,12.62ZM835.68,228.32c4.88,0,7.62-3.79,7.62-9.11s-2.74-9.11-7.62-9.11-7.51,3.73-7.51,9.11,2.69,9.11,7.51,9.11Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M879.92,206.54c6.25,0,8.45,4.33,8.45,9.76v14.87h-4.55v-14.48c0-4-1.65-6.53-5.32-6.53-3.4,0-6.14,2.85-6.14,7.24v13.77h-4.55v-14.48c0-4-1.65-6.53-5.32-6.53-3.4,0-6.09,2.85-6.09,7.24v13.77h-4.55v-23.97h4.55v4.28c1.15-2.91,3.95-4.94,7.63-4.94,4.28,0,6.8,2.08,7.84,5.21,1.26-2.96,4.22-5.21,8.06-5.21Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M898.06,240.39h-4.55v-33.19h4.55v5.05c1.04-2.96,4.12-5.7,8.61-5.7,7.08,0,10.97,5.59,10.97,12.67s-3.9,12.62-10.97,12.62c-4.5,0-7.57-2.69-8.61-5.65v14.21ZM897.95,219.87c0,5.27,3.35,8.34,7.68,8.34,4.72,0,7.3-3.51,7.3-9s-2.58-9-7.3-9c-4.33,0-7.68,3.07-7.68,8.28v1.37Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M940.58,226.46c0,1.26.71,1.86,1.86,1.86.55,0,1.43-.16,2.08-.55v2.47c-.82.82-1.81,1.43-3.68,1.43-2.47,0-4.22-1.87-4.5-4.72-1.32,2.8-4.72,4.88-8.61,4.88-4.66,0-7.68-2.52-7.68-6.64,0-4.55,3.89-6.42,9.66-7.57l6.47-1.32v-.93c0-3.29-1.92-5.32-5.27-5.32s-5.32,2.03-6.03,4.83l-4.06-.55c.93-4.5,4.5-7.79,10.15-7.79,6.04,0,9.6,3.07,9.6,9.16v10.75ZM936.19,219.32l-5.38,1.15c-3.78.82-6.2,1.54-6.2,4.44,0,2.03,1.37,3.57,4.11,3.57,4.22,0,7.46-3.18,7.46-8.01v-1.15Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M951.66,231.17h-4.55v-23.97h4.55v4.28c1.15-2.91,4.39-4.94,7.95-4.94,6.25,0,8.78,4.17,8.78,9.76v14.87h-4.55v-14.48c0-4-1.92-6.53-5.7-6.53s-6.47,2.85-6.47,7.24v13.77Z"
                ></path>
                <path
                    style={{ fill: secondaryFill }}
                    d="M975.85,240.39l3.89-9.27-10.09-23.92h4.77l7.62,18.43h.22l7.35-18.43h4.5l-13.82,33.19h-4.44Z"
                ></path>
            </g>
            <path
                style={{ fill: secondaryFill }}
                d="M328.17,130.26c0,22.69-18.12,34.04-40.08,34.04h-47.58c3.66-10.61,5.12-19.4,5.12-31.48v-56.18c0-12.08-1.28-20.68-5.12-31.29h48.13c18.85,0,34.59,8.97,34.59,27.63,0,15.92-10.25,26.17-22.88,29.65,11.71,2.38,27.82,9.15,27.82,27.63ZM272.91,53.76c-.18,7.87-.18,15.56-.18,22.88v23.61h9.15c10.43,0,14.27-8.78,14.27-21.59,0-17.02-6.77-24.89-18.3-24.89h-4.94ZM299.81,135.38c0-17.75-6.59-27.45-20.31-27.45-2.2,0-4.39.18-6.77.37v24.52c0,6.59,0,15.19.37,22.14,3.29.92,6.41,1.1,8.6,1.1,13.36,0,18.12-6.04,18.12-20.68Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M398.54,118.73c0-30.38,16.65-48.86,40.99-48.86,21.04,0,39.16,17.2,39.16,47.95s-16.65,48.86-41.17,48.86c-21.04,0-38.98-17.38-38.98-47.95ZM450.15,127.51c0-23.97-4.58-47.95-13.54-47.95-7.87,0-9.7,11.9-9.7,29.46,0,23.97,4.58,47.95,13.54,47.95,7.87,0,9.7-11.71,9.7-29.46Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M534.88,164.3c-1.83-3.48-3.29-7.69-3.66-12.26-6.22,8.23-14.82,13.72-25.62,13.72-12.26,0-20.13-7.87-20.13-19.21,0-13.91,13.18-19.95,27.27-26.17,16.1-7.14,18.3-8.78,18.3-13.18v-9.52c0-10.43-1.83-18.67-9.88-18.67-5.67,0-8.24,4.39-8.24,11.16,0,5.12,1.28,11.35,4.03,17.57l-27.45-1.83v-4.21c0-17.75,12.63-31.29,35.87-31.29s32.94,11.9,32.94,37.7v25.99c0,13.54.73,20.68,5.12,30.19h-28.55ZM531.03,147.09v-18.12c0-4.57-1.28-5.31-4.58-5.31-10.61,0-13.54,3.66-13.54,11.71s4.21,13.18,10.98,13.18c2.38,0,4.76-.55,7.14-1.46Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M623.73,164.3c-1.65-1.65-3.11-5.86-3.84-10.25-4.94,7.5-12.08,11.53-21.04,11.53-15.19,0-29.28-13.54-29.28-41.91,0-38.61,22.33-53.07,39.53-53.07,4.03,0,7.32.55,10.25,2.01v-4.58c0-9.15-.37-16.65-4.03-23.42,10.8-2.93,20.86-6.77,30.93-11.71v105.77c0,9.88.37,14.64,4.21,25.62h-26.72ZM619.34,147.64v-45.75c0-14.64-4.03-22.14-10.43-22.14-7.87,0-11.9,8.05-11.9,33.12s5.49,35.5,17.38,35.5c1.83,0,3.48-.37,4.94-.73Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M662.98,138.86v-68.99c0-9.33,0-16.65-3.84-25.07,10.61-2.93,20.5-6.77,30.74-11.53v105.59c0,10.43.37,16.29,4.21,25.44h-35.32c3.84-9.15,4.21-15.01,4.21-25.44Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M753.74,164.3c-1.65-3.48-3.29-10.06-3.66-15.01-5.12,10.8-14.64,16.65-25.07,16.65-12.99,0-19.76-9.15-19.76-25.8v-34.95c0-7.69-.18-12.63-4.21-23.24,10.61-3.48,20.68-7.14,31.11-11.89v66.61c0,8.23,2.01,12.08,8.23,12.08,2.93,0,6.41-1.1,9.52-4.76v-38.98c0-7.87-.37-15.92-2.74-23.61,10.25-3.11,19.4-6.77,29.65-11.9v68.26c0,8.97.55,15.92,4.03,26.53h-27.08Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M914.96,164.3h-29.65c-1.83-7.69-2.38-21.59-2.38-31.11v-33.67c0-9.33-2.75-12.08-8.24-12.08-2.93,0-6.59,1.28-9.7,5.31v40.08c0,9.88,1.46,21.04,4.94,31.48h-31.84v-64.78c0-9.33-2.75-12.08-8.05-12.08-3.11,0-6.59,1.28-9.7,5.31v46.12c0,10.43.18,16.29,4.21,25.44h-35.32c3.84-9.15,4.21-15.01,4.21-25.44v-35.5c0-9.15-.18-13.73-4.21-21.96,8.78-3.66,17.93-7.5,26.54-11.71,2.38,4.94,4.21,11.9,4.57,17.75,4.94-11.16,14.64-17.2,24.89-17.2,10.98,0,17.39,6.41,19.21,18.3,4.94-11.71,14.82-18.3,25.62-18.3,12.81,0,19.76,9.15,19.76,25.8v36.78c0,9.88,1.28,21.04,5.12,31.48Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M994.12,118.91h-46.85c2.01,15.37,8.6,24.89,27.63,24.89,8.42,0,13.72-1.83,18.85-4.21-1.65,10.98-11.89,27.08-33.49,27.08-23.24,0-39.71-19.03-39.71-47.58s14.27-49.04,40.81-49.04c22.69,0,32.76,16.1,32.76,35.32v13.54ZM966.67,105.92c0-19.58-3.66-26.35-9.52-26.35s-10.43,7.5-10.43,27.08c0,2.01,0,4.03.18,6.04h13.72c5.12,0,6.04-.37,6.04-6.77Z"
            ></path>
            <path
                style={{ fill: secondaryFill }}
                d="M387.25,107.9c1.67-14.06,6.68-25.45,14.23-33.59-2.68-1.89-10.64-4.59-16.2-4.07-10.75,1.01-18.12,4.21-21.96,20.5-1.1-6.77-3.66-16.47-6.95-21.04-8.97,4.94-17.02,8.6-26.35,12.81,5.31,9.33,7.14,17.02,7.14,27.08v27.82c0,9.88-.37,16.29-4.21,26.9h35.5c-4.03-10.61-4.39-17.02-4.39-26.9v-24.89c0-15.01,2.31-18.38,7.14-19.01,4.17-.55,6.46.38,16.04,14.44l.02-.03Z"
            ></path>
        </g>
        <g id="Layer_3" data-name="Layer 3">
            <g>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M159.81,18.03c1.69,27.92-19.44,53.55-47.38,56.24-2.54-1.78.88-16.54,1.85-19.6,6.29-19.75,24.86-34.74,45.53-36.64Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M193.25,53.2c-2.8,27.27-27.27,48.38-54.81,47.5.93-27.55,27.67-49.78,54.81-47.5Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M61.96,68.52c7.09,7.72,13.59,20.6,13.37,31.22-28.35,1.19-52.92-20.08-55.72-48.28,15.65-.3,31.65,5.41,42.35,17.06Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M75.32,109.95c-.62,28.17-28.2,50.35-55.7,48.28,2.23-23.08,21.34-43.59,44.19-47.26,3.7-.59,7.8-.07,11.51-1.02Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M87.06,35.07c9.34,10.27,13.51,23.77,14.06,37.52l-1.16,1.93c-27.76-3.73-48.77-28.29-46.92-56.49,6.55,1.16,12.26,2.49,18.28,5.39,5.01,2.41,12.02,7.56,15.74,11.65Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M100.39,135.95c2.15,28.26-19.33,53.04-47.35,55.71-1.95-27.61,19.73-53.56,47.35-55.71Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M159.81,191.66c-12.86-.74-25.41-7.1-33.9-16.7-9.28-10.51-14.81-24.44-13.47-38.56,27.84,1.27,48.46,28.23,47.37,55.27Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M178.31,126.59c8.06,8.06,13.23,20.51,14.93,31.64-28.06,2.02-51.73-19.31-55.71-46.57,14.58-1.34,30.47,4.62,40.78,14.92Z"
                ></path>
                <path
                    style={{
                        fill: primaryFill,
                    }}
                    d="M126.39,85.82c-4.14,12.02-4.09,26.98,0,38.98-13.61-3.52-26.31-3.53-39.92,0,3.55-13.17,3.55-25.81,0-38.99,13.54,2.74,26.37,2.22,39.92,0Z"
                ></path>
            </g>
        </g>
    </svg>
)
