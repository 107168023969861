import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'

import { useState } from 'react'
import { CustomInput } from '../components/custom-input'
import { PasswordHelperComponent } from '../components/PasswordErrorHelper'
import { PrimaryButton } from '../components/primary-button'
import { useCustomToast } from '../components/useCustomToast'
import { axiosInstance } from '../services/api'
import { resetPasswordSchema } from '../utils/validators/schemas'
import { OnboardingContainer } from './onboarding'

const route = getRouteApi('/$clientid/_onboarding/resetpassword')
export const Resetpassword = () => {
    const routeParams = route.useParams()
    const queryParams = route.useSearch()
    const router1 = useRouter()
    const [showPasswordPopup, setShowPasswordPopup] = useState(false)
    console.log('showPasswordPopup===', showPasswordPopup)
    const { toast } = useCustomToast()
    const navigate = route.useNavigate()
    const setPwdMutation = useMutation({
        mutationKey: ['setPassword'],
        mutationFn: async ({
            code,
            password,
            email,
        }: {
            code: string
            password: string
            email: string
        }) => {
            try {
                const result = await axiosInstance.post(`/resetPassword`, {
                    email,
                    password,
                    module: routeParams.clientid,
                    otp: code,
                })

                if (result.status !== 200) {
                    throw result.data.message
                }

                toast({
                    title: '✅ Password changed sucessfully!',
                    description: 'Please login to continue',
                    variant: 'success',
                })
                setTimeout(() => {
                    navigate({
                        to: '/$clientid/login',
                        params: routeParams,
                    })
                }, 2000)
            } catch (error) {
                console.log(error)
                toast({
                    title: 'Invalid Request',
                    description: error?.message?.name,
                    variant: 'error',
                })
            }
        },
    })
    const form = useForm({
        defaultValues: {
            confirmPassword: '',
            password: '',
            code: '',
            email: queryParams.email,
        },
        validators: {
            onMount: resetPasswordSchema,
            onChange: resetPasswordSchema,
        },
        validatorAdapter: zodValidator(),
        onSubmit: async ({ value }) => {
            if (value.password !== value.confirmPassword) {
                console.error('passwords should match')
                toast({
                    title: error?.response?.data?.message,
                    description: '',
                    variant: 'error',
                })
            }
            await setPwdMutation.mutateAsync({
                email: value.email,
                password: value.password,
                code: value.code,
            })
            router1.invalidate()
        },
    })

    const codeField = form.useField({
        name: 'code',
    })
    const passwordField = form.useField({
        name: 'password',
    })
    const confirmPasswordField = form.useField({
        name: 'confirmPassword',
    })
    const emailField = form.useField({
        name: 'email',
    })

    console.log('form====', form.state)

    return (
        <OnboardingContainer>
            <Card className="border-none lg:max-w-lg md:max-w-md mt-12 mx-auto p-4 rounded-2xl shadow-md sm:max-w-sm sm:mt-6 sm:p-6 sm:shadow-lg w-[90%] xl:max-w-xl">
                <CardHeader className="md:p-0 p-1">
                    <CardTitle className="font-bold font-sans mb-0 md:mb-0 not-italic text-2xl text-center">
                        Reset Password
                    </CardTitle>
                </CardHeader>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        form.handleSubmit()
                    }}
                >
                    <CardContent className="pb-0 md:p-1 px-1 flex flex-col">
                        <CustomInput
                            id={codeField.name}
                            name={codeField.name}
                            value={codeField.state.value}
                            onChange={(e) => codeField.setValue(e.target.value)}
                            isTouched={codeField.state.meta.isTouched}
                            errors={codeField.state.meta.errors}
                            onBlur={codeField.handleBlur}
                            label={'Confirmation Code'}
                            type={'text'}
                            maxLength={6}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />

                        <CustomInput
                            id={emailField.name}
                            name={emailField.name}
                            value={emailField.state.value}
                            onChange={(e) =>
                                emailField.setValue(e.target.value)
                            }
                            isTouched={emailField.state.meta.isTouched}
                            errors={emailField.state.meta.errors}
                            onBlur={emailField.handleBlur}
                            label={'Email'}
                            type={'email'}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />

                        <CustomInput
                            id={passwordField.name}
                            name={passwordField.name}
                            value={passwordField.state.value}
                            onChange={(e) =>
                                passwordField.setValue(e.target.value)
                            }
                            isTouched={passwordField.state.meta.isTouched}
                            onBlur={() => {
                                setShowPasswordPopup(false)
                                passwordField.handleBlur()
                            }}
                            onFocus={() => {
                                setShowPasswordPopup(true)
                            }}
                            autoComplete={'current-password'}
                            label={'Password'}
                            type={'password'}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />

                        <PasswordHelperComponent
                            showPasswordPopup={showPasswordPopup}
                            errors={passwordField.state.meta.errors}
                        />

                        <CustomInput
                            id={confirmPasswordField.name}
                            name={confirmPasswordField.name}
                            value={confirmPasswordField.state.value}
                            onChange={(e) =>
                                confirmPasswordField.setValue(e.target.value)
                            }
                            isTouched={
                                confirmPasswordField.state.meta.isTouched
                            }
                            errors={confirmPasswordField.state.meta.errors}
                            onBlur={confirmPasswordField.handleBlur}
                            label={'Confirm Password'}
                            type={'password'}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />
                    </CardContent>
                    <CardFooter className="grid grid-cols-1 py-5">
                        <PrimaryButton
                            type="submit"
                            title="Submit"
                            className="text-center py-5 w-fit px-12 justify-self-center"
                            isLoading={form.state.isSubmitting}
                            disabled={
                                !(
                                    !form.state.isPristine && form.state.isValid
                                ) || form.state.isSubmitting
                            }
                        />
                    </CardFooter>
                </form>
            </Card>
        </OnboardingContainer>
    )
}

export default Resetpassword
