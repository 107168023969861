import { createFileRoute, redirect } from '@tanstack/react-router'
import { authQueryFn, getTabs } from '../../services/auth'
import * as Sentry from '@sentry/react'

export const Route = createFileRoute('/$clientid/_onboarding')({
    beforeLoad: async ({ params, context, location }) => {
        const { queryClient } = context
        const data = await queryClient.fetchQuery({
            queryKey: ['authKeys', params.clientid],
            queryFn: () => authQueryFn(true),
        })

        // We will add authentication here later
        // Route users to default broadlume page in case of missing client id.
        console.log(
            'beforeload onboarding params.clientid',
            params,
            location,
            data
        )

        if (!params?.clientid) {
            throw redirect({
                to: '/404',
            })
        }
        if (!/^[a-z]+$/.test(params.clientid)) {
            params.clientid = params.clientid.toLowerCase()
            location.pathname = location.pathname.toLowerCase()

            throw redirect({
                to: location.pathname,
                params,
            })
        }
        await queryClient.setQueryData(['clientId'], params.clientid)
        if (data.accessToken && data.idToken && data.refreshToken) {
            // Yardi redirection handling
            const source = new URLSearchParams(window.location.search).get(
                'source'
            )
            if (source && source === 'yardi') {
                throw redirect({
                    to: '/$clientid/place-order',
                    params,
                    search: {
                        source,
                    },
                })
            } else {
                const tabList = getTabs({ userDetails: data?.userDetails })
                if (!tabList.length) {
                    if (!data?.userDetails?.Properties?.length) {
                        Sentry.captureException(
                            'No Properties found for user.',
                            {
                                extra: {
                                    userDetails: JSON.stringify(
                                        data?.userDetails
                                    ),
                                },
                            }
                        )
                    }

                    if (!data?.userDetails?.access?.length) {
                        Sentry.captureException(
                            'No Page access found for user.',
                            {
                                extra: {
                                    userDetails: JSON.stringify(
                                        data?.userDetails
                                    ),
                                },
                            }
                        )
                    }

                    throw redirect({
                        to: '/$clientid/401',
                        params,
                    })
                }
                throw redirect({
                    to: '/$clientid/' + tabList[0].id,
                    params,
                })
            }
        }
    },
})
