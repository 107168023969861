import { Input, InputProps, Label } from '@broadlume/willow-ui'
import { ValidationError } from '@tanstack/react-form'
import { ErrorComponent } from './ErrorComponent'

export const CustomInput = ({
    id,
    label,
    errors,
    isTouched,
    classNames = {
        root: [],
        label: [],
        input: [],
    },
    customErrorComponent: CustomErrorComponent,
    otherProps = {
        labelProps: {},
    },
    ...rest
}: {
    id: string
    label?: string
    errors?: ValidationError[]
    isTouched?: boolean
    classNames?: {
        root?: string[]
        label?: string[]
        input?: string[]
    }
    otherProps?: { labelProps?: Parameters<typeof Label>[0] }
    customErrorComponent?: ({
        errorList,
    }: {
        errorList: string[]
    }) => JSX.Element
} & InputProps) => {
    return (
        <div className={`flex flex-col ${classNames.root}`}>
            {label && (
                <Label
                    className={`mb-[7px] text-pretty leading-[normal] ${classNames.label}`}
                    htmlFor={id}
                    {...otherProps.labelProps}
                >
                    {label}
                    {rest.required && (
                        <span className="text-willow-danger">*</span>
                    )}
                </Label>
            )}
            <Input id={id} {...rest} className={`${classNames.input}`} />
            <ErrorComponent
                customErrorComponent={CustomErrorComponent}
                isTouched={isTouched}
                errors={errors}
            />
        </div>
    )
}
