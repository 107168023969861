import { Button } from '@broadlume/willow-ui'
import { PrimaryButton } from './primary-button'
import clsx from 'classnames'
import LoadingComponent from './LoadingComponent'

const SecondaryButton: React.FC<Parameters<typeof PrimaryButton>[0]> = ({
    title,
    children,
    className = '',
    isLoading = false,
    ...rest
}) => (
    <Button
        {...rest}
        variant="outline"
        type="button"
        className={clsx(
            'text-xs',
            rest.disabled ? 'opacity-60' : '',
            'border bg-custom-secondary_button_background hover:bg-custom-secondary_button_background custom-secondary-button ',
            'hover:opacity-85 hover:text-custom-secondary_button_text',
            className || ''
        )}
    >
        {isLoading && <LoadingComponent classNames="w-4 h-4 mr-2" />}
        {title ?? children}
    </Button>
)

export { SecondaryButton }
