/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ClientidIndexImport } from './routes/$clientid/index'
import { Route as ClientidOnboardingImport } from './routes/$clientid/_onboarding'
import { Route as ClientidAppImport } from './routes/$clientid/_app'
import { Route as ClientidOnboardingResetpasswordImport } from './routes/$clientid/_onboarding/resetpassword'
import { Route as ClientidOnboardingLoginImport } from './routes/$clientid/_onboarding/login'
import { Route as ClientidAppPlaceOrderImport } from './routes/$clientid/_app/place-order'

// Create Virtual Routes

const ClientidImport = createFileRoute('/$clientid')()
const R404IndexLazyImport = createFileRoute('/404/')()
const Clientid401IndexLazyImport = createFileRoute('/$clientid/401/')()
const ClientidOnboardingForgotpasswordLazyImport = createFileRoute(
    '/$clientid/_onboarding/forgotpassword'
)()
const ClientidAppUserAdminLazyImport = createFileRoute(
    '/$clientid/_app/user-admin'
)()
const ClientidAppSettingsLazyImport = createFileRoute(
    '/$clientid/_app/settings'
)()
const ClientidAppOrdersLazyImport = createFileRoute('/$clientid/_app/orders')()
const ClientidAppOrderAnalysisLazyImport = createFileRoute(
    '/$clientid/_app/order-analysis'
)()
const ClientidAppInvoicesLazyImport = createFileRoute(
    '/$clientid/_app/invoices'
)()
const ClientidAppDashboardIndexLazyImport = createFileRoute(
    '/$clientid/_app/dashboard/'
)()

// Create/Update Routes

const ClientidRoute = ClientidImport.update({
    id: '/$clientid',
    path: '/$clientid',
    getParentRoute: () => rootRoute,
} as any)

const R404IndexLazyRoute = R404IndexLazyImport.update({
    id: '/404/',
    path: '/404/',
    getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/404/index.lazy').then((d) => d.Route))

const ClientidIndexRoute = ClientidIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => ClientidRoute,
} as any)

const ClientidOnboardingRoute = ClientidOnboardingImport.update({
    id: '/_onboarding',
    getParentRoute: () => ClientidRoute,
} as any)

const ClientidAppRoute = ClientidAppImport.update({
    id: '/_app',
    getParentRoute: () => ClientidRoute,
} as any)

const Clientid401IndexLazyRoute = Clientid401IndexLazyImport.update({
    id: '/401/',
    path: '/401/',
    getParentRoute: () => ClientidRoute,
} as any).lazy(() =>
    import('./routes/$clientid/401/index.lazy').then((d) => d.Route)
)

const ClientidOnboardingForgotpasswordLazyRoute =
    ClientidOnboardingForgotpasswordLazyImport.update({
        id: '/forgotpassword',
        path: '/forgotpassword',
        getParentRoute: () => ClientidOnboardingRoute,
    } as any).lazy(() =>
        import('./routes/$clientid/_onboarding/forgotpassword.lazy').then(
            (d) => d.Route
        )
    )

const ClientidAppUserAdminLazyRoute = ClientidAppUserAdminLazyImport.update({
    id: '/user-admin',
    path: '/user-admin',
    getParentRoute: () => ClientidAppRoute,
} as any).lazy(() =>
    import('./routes/$clientid/_app/user-admin.lazy').then((d) => d.Route)
)

const ClientidAppSettingsLazyRoute = ClientidAppSettingsLazyImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => ClientidAppRoute,
} as any).lazy(() =>
    import('./routes/$clientid/_app/settings.lazy').then((d) => d.Route)
)

const ClientidAppOrdersLazyRoute = ClientidAppOrdersLazyImport.update({
    id: '/orders',
    path: '/orders',
    getParentRoute: () => ClientidAppRoute,
} as any).lazy(() =>
    import('./routes/$clientid/_app/orders.lazy').then((d) => d.Route)
)

const ClientidAppOrderAnalysisLazyRoute =
    ClientidAppOrderAnalysisLazyImport.update({
        id: '/order-analysis',
        path: '/order-analysis',
        getParentRoute: () => ClientidAppRoute,
    } as any).lazy(() =>
        import('./routes/$clientid/_app/order-analysis.lazy').then(
            (d) => d.Route
        )
    )

const ClientidAppInvoicesLazyRoute = ClientidAppInvoicesLazyImport.update({
    id: '/invoices',
    path: '/invoices',
    getParentRoute: () => ClientidAppRoute,
} as any).lazy(() =>
    import('./routes/$clientid/_app/invoices.lazy').then((d) => d.Route)
)

const ClientidOnboardingResetpasswordRoute =
    ClientidOnboardingResetpasswordImport.update({
        id: '/resetpassword',
        path: '/resetpassword',
        getParentRoute: () => ClientidOnboardingRoute,
    } as any)

const ClientidOnboardingLoginRoute = ClientidOnboardingLoginImport.update({
    id: '/login',
    path: '/login',
    getParentRoute: () => ClientidOnboardingRoute,
} as any)

const ClientidAppPlaceOrderRoute = ClientidAppPlaceOrderImport.update({
    id: '/place-order',
    path: '/place-order',
    getParentRoute: () => ClientidAppRoute,
} as any)

const ClientidAppDashboardIndexLazyRoute =
    ClientidAppDashboardIndexLazyImport.update({
        id: '/dashboard/',
        path: '/dashboard/',
        getParentRoute: () => ClientidAppRoute,
    } as any).lazy(() =>
        import('./routes/$clientid/_app/dashboard/index.lazy').then(
            (d) => d.Route
        )
    )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
    interface FileRoutesByPath {
        '/$clientid': {
            id: '/$clientid'
            path: '/$clientid'
            fullPath: '/$clientid'
            preLoaderRoute: typeof ClientidImport
            parentRoute: typeof rootRoute
        }
        '/$clientid/_app': {
            id: '/$clientid/_app'
            path: '/$clientid'
            fullPath: '/$clientid'
            preLoaderRoute: typeof ClientidAppImport
            parentRoute: typeof ClientidRoute
        }
        '/$clientid/_onboarding': {
            id: '/$clientid/_onboarding'
            path: ''
            fullPath: '/$clientid'
            preLoaderRoute: typeof ClientidOnboardingImport
            parentRoute: typeof ClientidImport
        }
        '/$clientid/': {
            id: '/$clientid/'
            path: '/'
            fullPath: '/$clientid/'
            preLoaderRoute: typeof ClientidIndexImport
            parentRoute: typeof ClientidImport
        }
        '/404/': {
            id: '/404/'
            path: '/404'
            fullPath: '/404'
            preLoaderRoute: typeof R404IndexLazyImport
            parentRoute: typeof rootRoute
        }
        '/$clientid/_app/place-order': {
            id: '/$clientid/_app/place-order'
            path: '/place-order'
            fullPath: '/$clientid/place-order'
            preLoaderRoute: typeof ClientidAppPlaceOrderImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_onboarding/login': {
            id: '/$clientid/_onboarding/login'
            path: '/login'
            fullPath: '/$clientid/login'
            preLoaderRoute: typeof ClientidOnboardingLoginImport
            parentRoute: typeof ClientidOnboardingImport
        }
        '/$clientid/_onboarding/resetpassword': {
            id: '/$clientid/_onboarding/resetpassword'
            path: '/resetpassword'
            fullPath: '/$clientid/resetpassword'
            preLoaderRoute: typeof ClientidOnboardingResetpasswordImport
            parentRoute: typeof ClientidOnboardingImport
        }
        '/$clientid/_app/invoices': {
            id: '/$clientid/_app/invoices'
            path: '/invoices'
            fullPath: '/$clientid/invoices'
            preLoaderRoute: typeof ClientidAppInvoicesLazyImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_app/order-analysis': {
            id: '/$clientid/_app/order-analysis'
            path: '/order-analysis'
            fullPath: '/$clientid/order-analysis'
            preLoaderRoute: typeof ClientidAppOrderAnalysisLazyImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_app/orders': {
            id: '/$clientid/_app/orders'
            path: '/orders'
            fullPath: '/$clientid/orders'
            preLoaderRoute: typeof ClientidAppOrdersLazyImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_app/settings': {
            id: '/$clientid/_app/settings'
            path: '/settings'
            fullPath: '/$clientid/settings'
            preLoaderRoute: typeof ClientidAppSettingsLazyImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_app/user-admin': {
            id: '/$clientid/_app/user-admin'
            path: '/user-admin'
            fullPath: '/$clientid/user-admin'
            preLoaderRoute: typeof ClientidAppUserAdminLazyImport
            parentRoute: typeof ClientidAppImport
        }
        '/$clientid/_onboarding/forgotpassword': {
            id: '/$clientid/_onboarding/forgotpassword'
            path: '/forgotpassword'
            fullPath: '/$clientid/forgotpassword'
            preLoaderRoute: typeof ClientidOnboardingForgotpasswordLazyImport
            parentRoute: typeof ClientidOnboardingImport
        }
        '/$clientid/401/': {
            id: '/$clientid/401/'
            path: '/401'
            fullPath: '/$clientid/401'
            preLoaderRoute: typeof Clientid401IndexLazyImport
            parentRoute: typeof ClientidImport
        }
        '/$clientid/_app/dashboard/': {
            id: '/$clientid/_app/dashboard/'
            path: '/dashboard'
            fullPath: '/$clientid/dashboard'
            preLoaderRoute: typeof ClientidAppDashboardIndexLazyImport
            parentRoute: typeof ClientidAppImport
        }
    }
}

// Create and export the route tree

interface ClientidAppRouteChildren {
    ClientidAppPlaceOrderRoute: typeof ClientidAppPlaceOrderRoute
    ClientidAppInvoicesLazyRoute: typeof ClientidAppInvoicesLazyRoute
    ClientidAppOrderAnalysisLazyRoute: typeof ClientidAppOrderAnalysisLazyRoute
    ClientidAppOrdersLazyRoute: typeof ClientidAppOrdersLazyRoute
    ClientidAppSettingsLazyRoute: typeof ClientidAppSettingsLazyRoute
    ClientidAppUserAdminLazyRoute: typeof ClientidAppUserAdminLazyRoute
    ClientidAppDashboardIndexLazyRoute: typeof ClientidAppDashboardIndexLazyRoute
}

const ClientidAppRouteChildren: ClientidAppRouteChildren = {
    ClientidAppPlaceOrderRoute: ClientidAppPlaceOrderRoute,
    ClientidAppInvoicesLazyRoute: ClientidAppInvoicesLazyRoute,
    ClientidAppOrderAnalysisLazyRoute: ClientidAppOrderAnalysisLazyRoute,
    ClientidAppOrdersLazyRoute: ClientidAppOrdersLazyRoute,
    ClientidAppSettingsLazyRoute: ClientidAppSettingsLazyRoute,
    ClientidAppUserAdminLazyRoute: ClientidAppUserAdminLazyRoute,
    ClientidAppDashboardIndexLazyRoute: ClientidAppDashboardIndexLazyRoute,
}

const ClientidAppRouteWithChildren = ClientidAppRoute._addFileChildren(
    ClientidAppRouteChildren
)

interface ClientidOnboardingRouteChildren {
    ClientidOnboardingLoginRoute: typeof ClientidOnboardingLoginRoute
    ClientidOnboardingResetpasswordRoute: typeof ClientidOnboardingResetpasswordRoute
    ClientidOnboardingForgotpasswordLazyRoute: typeof ClientidOnboardingForgotpasswordLazyRoute
}

const ClientidOnboardingRouteChildren: ClientidOnboardingRouteChildren = {
    ClientidOnboardingLoginRoute: ClientidOnboardingLoginRoute,
    ClientidOnboardingResetpasswordRoute: ClientidOnboardingResetpasswordRoute,
    ClientidOnboardingForgotpasswordLazyRoute:
        ClientidOnboardingForgotpasswordLazyRoute,
}

const ClientidOnboardingRouteWithChildren =
    ClientidOnboardingRoute._addFileChildren(ClientidOnboardingRouteChildren)

interface ClientidRouteChildren {
    ClientidAppRoute: typeof ClientidAppRouteWithChildren
    ClientidOnboardingRoute: typeof ClientidOnboardingRouteWithChildren
    ClientidIndexRoute: typeof ClientidIndexRoute
    Clientid401IndexLazyRoute: typeof Clientid401IndexLazyRoute
}

const ClientidRouteChildren: ClientidRouteChildren = {
    ClientidAppRoute: ClientidAppRouteWithChildren,
    ClientidOnboardingRoute: ClientidOnboardingRouteWithChildren,
    ClientidIndexRoute: ClientidIndexRoute,
    Clientid401IndexLazyRoute: Clientid401IndexLazyRoute,
}

const ClientidRouteWithChildren = ClientidRoute._addFileChildren(
    ClientidRouteChildren
)

export interface FileRoutesByFullPath {
    '/$clientid': typeof ClientidOnboardingRouteWithChildren
    '/$clientid/': typeof ClientidIndexRoute
    '/404': typeof R404IndexLazyRoute
    '/$clientid/place-order': typeof ClientidAppPlaceOrderRoute
    '/$clientid/login': typeof ClientidOnboardingLoginRoute
    '/$clientid/resetpassword': typeof ClientidOnboardingResetpasswordRoute
    '/$clientid/invoices': typeof ClientidAppInvoicesLazyRoute
    '/$clientid/order-analysis': typeof ClientidAppOrderAnalysisLazyRoute
    '/$clientid/orders': typeof ClientidAppOrdersLazyRoute
    '/$clientid/settings': typeof ClientidAppSettingsLazyRoute
    '/$clientid/user-admin': typeof ClientidAppUserAdminLazyRoute
    '/$clientid/forgotpassword': typeof ClientidOnboardingForgotpasswordLazyRoute
    '/$clientid/401': typeof Clientid401IndexLazyRoute
    '/$clientid/dashboard': typeof ClientidAppDashboardIndexLazyRoute
}

export interface FileRoutesByTo {
    '/$clientid': typeof ClientidIndexRoute
    '/404': typeof R404IndexLazyRoute
    '/$clientid/place-order': typeof ClientidAppPlaceOrderRoute
    '/$clientid/login': typeof ClientidOnboardingLoginRoute
    '/$clientid/resetpassword': typeof ClientidOnboardingResetpasswordRoute
    '/$clientid/invoices': typeof ClientidAppInvoicesLazyRoute
    '/$clientid/order-analysis': typeof ClientidAppOrderAnalysisLazyRoute
    '/$clientid/orders': typeof ClientidAppOrdersLazyRoute
    '/$clientid/settings': typeof ClientidAppSettingsLazyRoute
    '/$clientid/user-admin': typeof ClientidAppUserAdminLazyRoute
    '/$clientid/forgotpassword': typeof ClientidOnboardingForgotpasswordLazyRoute
    '/$clientid/401': typeof Clientid401IndexLazyRoute
    '/$clientid/dashboard': typeof ClientidAppDashboardIndexLazyRoute
}

export interface FileRoutesById {
    __root__: typeof rootRoute
    '/$clientid': typeof ClientidRouteWithChildren
    '/$clientid/_app': typeof ClientidAppRouteWithChildren
    '/$clientid/_onboarding': typeof ClientidOnboardingRouteWithChildren
    '/$clientid/': typeof ClientidIndexRoute
    '/404/': typeof R404IndexLazyRoute
    '/$clientid/_app/place-order': typeof ClientidAppPlaceOrderRoute
    '/$clientid/_onboarding/login': typeof ClientidOnboardingLoginRoute
    '/$clientid/_onboarding/resetpassword': typeof ClientidOnboardingResetpasswordRoute
    '/$clientid/_app/invoices': typeof ClientidAppInvoicesLazyRoute
    '/$clientid/_app/order-analysis': typeof ClientidAppOrderAnalysisLazyRoute
    '/$clientid/_app/orders': typeof ClientidAppOrdersLazyRoute
    '/$clientid/_app/settings': typeof ClientidAppSettingsLazyRoute
    '/$clientid/_app/user-admin': typeof ClientidAppUserAdminLazyRoute
    '/$clientid/_onboarding/forgotpassword': typeof ClientidOnboardingForgotpasswordLazyRoute
    '/$clientid/401/': typeof Clientid401IndexLazyRoute
    '/$clientid/_app/dashboard/': typeof ClientidAppDashboardIndexLazyRoute
}

export interface FileRouteTypes {
    fileRoutesByFullPath: FileRoutesByFullPath
    fullPaths:
        | '/$clientid'
        | '/$clientid/'
        | '/404'
        | '/$clientid/place-order'
        | '/$clientid/login'
        | '/$clientid/resetpassword'
        | '/$clientid/invoices'
        | '/$clientid/order-analysis'
        | '/$clientid/orders'
        | '/$clientid/settings'
        | '/$clientid/user-admin'
        | '/$clientid/forgotpassword'
        | '/$clientid/401'
        | '/$clientid/dashboard'
    fileRoutesByTo: FileRoutesByTo
    to:
        | '/$clientid'
        | '/404'
        | '/$clientid/place-order'
        | '/$clientid/login'
        | '/$clientid/resetpassword'
        | '/$clientid/invoices'
        | '/$clientid/order-analysis'
        | '/$clientid/orders'
        | '/$clientid/settings'
        | '/$clientid/user-admin'
        | '/$clientid/forgotpassword'
        | '/$clientid/401'
        | '/$clientid/dashboard'
    id:
        | '__root__'
        | '/$clientid'
        | '/$clientid/_app'
        | '/$clientid/_onboarding'
        | '/$clientid/'
        | '/404/'
        | '/$clientid/_app/place-order'
        | '/$clientid/_onboarding/login'
        | '/$clientid/_onboarding/resetpassword'
        | '/$clientid/_app/invoices'
        | '/$clientid/_app/order-analysis'
        | '/$clientid/_app/orders'
        | '/$clientid/_app/settings'
        | '/$clientid/_app/user-admin'
        | '/$clientid/_onboarding/forgotpassword'
        | '/$clientid/401/'
        | '/$clientid/_app/dashboard/'
    fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
    ClientidRoute: typeof ClientidRouteWithChildren
    R404IndexLazyRoute: typeof R404IndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
    ClientidRoute: ClientidRouteWithChildren,
    R404IndexLazyRoute: R404IndexLazyRoute,
}

export const routeTree = rootRoute
    ._addFileChildren(rootRouteChildren)
    ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/$clientid",
        "/404/"
      ]
    },
    "/$clientid": {
      "filePath": "$clientid",
      "children": [
        "/$clientid/_app",
        "/$clientid/_onboarding",
        "/$clientid/",
        "/$clientid/401/"
      ]
    },
    "/$clientid/_app": {
      "filePath": "$clientid/_app.tsx",
      "parent": "/$clientid",
      "children": [
        "/$clientid/_app/place-order",
        "/$clientid/_app/invoices",
        "/$clientid/_app/order-analysis",
        "/$clientid/_app/orders",
        "/$clientid/_app/settings",
        "/$clientid/_app/user-admin",
        "/$clientid/_app/dashboard/"
      ]
    },
    "/$clientid/_onboarding": {
      "filePath": "$clientid/_onboarding.tsx",
      "parent": "/$clientid",
      "children": [
        "/$clientid/_onboarding/login",
        "/$clientid/_onboarding/resetpassword",
        "/$clientid/_onboarding/forgotpassword"
      ]
    },
    "/$clientid/": {
      "filePath": "$clientid/index.tsx",
      "parent": "/$clientid"
    },
    "/404/": {
      "filePath": "404/index.lazy.tsx"
    },
    "/$clientid/_app/place-order": {
      "filePath": "$clientid/_app/place-order.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_onboarding/login": {
      "filePath": "$clientid/_onboarding/login.tsx",
      "parent": "/$clientid/_onboarding"
    },
    "/$clientid/_onboarding/resetpassword": {
      "filePath": "$clientid/_onboarding/resetpassword.tsx",
      "parent": "/$clientid/_onboarding"
    },
    "/$clientid/_app/invoices": {
      "filePath": "$clientid/_app/invoices.lazy.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_app/order-analysis": {
      "filePath": "$clientid/_app/order-analysis.lazy.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_app/orders": {
      "filePath": "$clientid/_app/orders.lazy.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_app/settings": {
      "filePath": "$clientid/_app/settings.lazy.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_app/user-admin": {
      "filePath": "$clientid/_app/user-admin.lazy.tsx",
      "parent": "/$clientid/_app"
    },
    "/$clientid/_onboarding/forgotpassword": {
      "filePath": "$clientid/_onboarding/forgotpassword.lazy.tsx",
      "parent": "/$clientid/_onboarding"
    },
    "/$clientid/401/": {
      "filePath": "$clientid/401/index.lazy.tsx",
      "parent": "/$clientid"
    },
    "/$clientid/_app/dashboard/": {
      "filePath": "$clientid/_app/dashboard/index.lazy.tsx",
      "parent": "/$clientid/_app"
    }
  }
}
ROUTE_MANIFEST_END */
