import {
    ToastProvider,
    Toast,
    ToastTitle,
    ToastDescription,
    ToastViewport,
    useToast,
} from '@broadlume/willow-ui'
import clsx from 'classnames'
import {
    MdError,
    MdCheckCircle,
    MdInfoOutline,
    MdOutlineWarning,
} from 'react-icons/md'

// Variant-specific classes and icon
const variantMap: Record<string, { className: string; icon: JSX.Element }> = {
    success: {
        className:
            'bg-gradient-to-r from-willow-beryl to-60% to-willow-ash-lightest text-willow-wool-ash',
        icon: <MdCheckCircle size={30} className="text-willow-wool-light" />,
    },
    error: {
        className:
            'bg-gradient-to-r from-willow-danger to-60% to-willow-ash-lightest text-willow-wool-ash',
        icon: <MdError size={30} className="text-willow-wool-light" />,
    },
    info: {
        className:
            'bg-gradient-to-r from-willow-ribbon to-60% to-willow-ash-lightest text-willow-wool-ash',
        icon: <MdInfoOutline size={30} className="text-willow-wool-light" />,
    },
    warning: {
        className:
            'bg-gradient-to-r from-willow-gold to-60% to-willow-ash-lightest text-willow-wool-ash',
        icon: <MdOutlineWarning size={30} className="text-willow-wool-light" />,
    },
}

export const CustomToast: React.FC = () => {
    const { toasts } = useToast()

    return (
        <>
            <ToastProvider swipeDirection="left">
                {toasts.map(({ id, title, description, variant, ...props }) => (
                    <Toast
                        key={id}
                        style={{
                            position: 'fixed',
                            bottom: '1rem',
                            right: '1rem',
                            zIndex: 1000,
                            width: 'auto', // Allows the toast to size dynamically
                            minWidth: '280px', // Ensures it doesn’t get too small
                            maxWidth: '400px', // Prevents excessive width on large screens
                            padding: '1rem',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        className={clsx(
                            'rounded-lg shadow-lg border-0 p-4 md:p-5',
                            'animate-fade-in-right data-[state="closed"]:animate-fade-out-right',
                            variantMap[variant || 'success'].className
                        )}
                        {...props}
                    >
                        <div className="flex items-center w-full gap-3">
                            {/* Icon - Responsive and Sized Well */}
                            <div className="flex-shrink-0 flex-[0.1] flex items-center justify-center">
                                {variantMap[variant || 'success'].icon}
                            </div>

                            {/* Text Content */}
                            <div className="flex-[0.9] pl-1">
                                <ToastTitle className="font-bold text-sm md:text-md">
                                    {title}
                                </ToastTitle>
                                <ToastDescription className="text-sm text-willow-ash-medium">
                                    {description}
                                </ToastDescription>
                            </div>
                        </div>
                    </Toast>
                ))}
                <ToastViewport className="fixed bottom-4 right-4 flex flex-col gap-3" />
            </ToastProvider>
        </>
    )
}
