import { QueryClient } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

export const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSettled(data, error, variables, context) {
                if (error) {
                    console.error('Mutation error:', error, variables, context)
                    if (![301].includes(error.statusCode)) {
                        Sentry.captureException(
                            error?.response?.data?.message || error,
                            {
                                extra: { variables, context, error },
                            }
                        )
                    }

                    return error
                }
                return data
            },
        },
    },
})
