import {
    GetCustomerAPIResopnse,
    GetPOrdFileAPIResopnse,
} from '../interfaces/place-order.interface'

import { CustomerAddress } from '../../pages/dashboard/types'
import { axiosInstance } from '../../services/api'
import { getCustomer, getPordFile } from '../../services/placeOrder'
import { useAuthHook } from './auth.hooks'
import { useSentryQuery } from '../useSentryQuery'

export const useGetPordFile = () => {
    const { data: { userDetails } = {} } = useAuthHook()
    return useSentryQuery<GetPOrdFileAPIResopnse>({
        queryKey: ['getPordFile', userDetails?.id],
        queryFn: getPordFile,
        staleTime: Infinity,
        enabled: true,
    })
}

export const useGetCustAddr = ({
    branch,
    company,
    properties,
    isEnabled = true,
}: {
    branch?: string
    company?: string
    properties?: string
    isEnabled?: boolean
} = {}) => {
    const { data: { userDetails } = {} } = useAuthHook()
    return useSentryQuery<CustomerAddress[]>({
        queryKey: [
            'get_custaddr',
            userDetails?.id,
            branch,
            company,
            properties,
        ],
        queryFn: async ({ signal }) => {
            const resp = await axiosInstance.get('/api/get_custaddr', {
                params: {
                    function: 'get_custaddr',
                    alias: userDetails.api_config,
                    branch:
                        branch || userDetails.Branches || userDetails.Branch,
                    company: company || userDetails.Company,
                    properties: properties || userDetails.Properties,
                },
                signal,
            })
            return resp.data
        },
        enabled: isEnabled,
        staleTime: Infinity,
    })
}

export const useGetCustomer = () => {
    const { data: { userDetails } = {} } = useAuthHook()
    return useSentryQuery<GetCustomerAPIResopnse>({
        queryKey: ['getCustomer', userDetails?.id],
        queryFn: getCustomer,
        staleTime: Infinity,
        enabled: true,
    })
}
