import {
    Checkbox,
    Label,
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@broadlume/willow-ui'
import { isMobileOrTablet } from '../utils'

const PASSWORD_ERROR_LIST = [
    '8-character minimum length',
    'Contains at least 1 number',
    'Contains at least 1 lowercase letter',
    'Contains at least 1 uppercase letter',
    'Contains at least 1 special character from the following set',
]
export const PasswordHelperComponent = ({
    errors,
    showPasswordPopup,
}: {
    errors: string
    showPasswordPopup: boolean
}) => {
    const errorList = (errors && (errors[0] as string))?.split(',') || []
    if (!errorList.length) {
        return null
    }
    return (
        <TooltipProvider>
            <Tooltip open={showPasswordPopup}>
                <TooltipTrigger />

                <TooltipContent
                    side={isMobileOrTablet ? 'top' : 'bottom'}
                    className="bg-willow-wool-light rounded-lg p-4 shadow-2xl max-w-[75%] max-md:mx-8 max-md:my-20 max-md:max-w-[60%]"
                >
                    <div className="">
                        <p className="mb-3 font-semibold text-lg md:text-xl">
                            Requirements:
                        </p>
                        <div className="flex flex-col gap-4">
                            {errorList.length
                                ? PASSWORD_ERROR_LIST.map((error, index) => {
                                      const foundError = errorList.findIndex(
                                          (err) => err.trim() === error
                                      )
                                      return (
                                          <div
                                              key={index}
                                              className="text-sm flex items-center gap-3 md:gap-4"
                                          >
                                              <Checkbox
                                                  className="data-[state=checked]:bg-[#4BB543] border-willow-ash-lighter cursor-default"
                                                  onClick={(e) =>
                                                      e.stopPropagation()
                                                  }
                                                  checked={foundError === -1}
                                                  id={'pass-err-' + index}
                                              />
                                              <Label
                                                  htmlFor={'pass-err-' + index}
                                              >
                                                  {error}
                                              </Label>
                                          </div>
                                      )
                                  })
                                : null}
                        </div>
                        <div className="text-sm mt-3 font-bold text-center">
                            <span className="text-willow-danger">*</span> ! @ #
                            $ % ^ & *
                        </div>
                    </div>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
