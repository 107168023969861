import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useNavigate, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'

import { CustomInput } from '../components/custom-input'
import { PrimaryButton } from '../components/primary-button'
import { useCustomToast } from '../components/useCustomToast'
import { setAndVerifyToken, signIn } from '../services/auth'
import { loginSchema } from '../utils/validators/schemas'
import { OnboardingContainer } from './onboarding'
import {
    useFetchCustomTheme,
    useFetchPortalConfig,
} from './settings-customHook'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

const router = getRouteApi('/$clientid/_onboarding/login')

const Login = () => {
    const params = router.useParams()
    const router1 = useRouter()
    const search = router.useSearch()
    const navigate = useNavigate({ from: '/' })
    const { toast } = useCustomToast()
    const { data: portalConfigData } = useFetchPortalConfig()
    const { data: themeConfig } = useFetchCustomTheme()

    // Replace &amp; with & in the query string and redirect
    useEffect(() => {
        const handleEffect = async () => {
            const currentUrl = window.location.href

            if (currentUrl.includes('&amp;')) {
                const updatedUrl = currentUrl.replace(/&amp;/g, '&')
                window.location.replace(updatedUrl)
            }

            if (search?.source === 'yardi' && search?.token?.length) {
                toast({
                    title: 'Authenticating the User...',
                    description: 'Please wait till we authenticate the user...',
                    variant: 'info',
                })
                try {
                    const accessToken = search.token!
                    if (accessToken.length > 0) {
                        await setAndVerifyToken({
                            IdToken: accessToken,
                            AccessToken: accessToken,
                            RefreshToken: accessToken,
                        })

                        navigate({
                            search: true,
                            to: '/$clientid/place-order',
                            params,
                        })
                    }
                } catch (error) {
                    console.error('Error refreshing token:', error)
                }
            }
        }

        handleEffect()
    }, [])

    const loginMutation = useMutation({
        mutationKey: ['login'],
        mutationFn: signIn,
    })
    const form = useForm({
        defaultValues: {
            email: '',
            password: '',
            code: undefined,
            session: undefined,
            username: undefined,
        },
        validators: {
            onChange: loginSchema,
            onMount: loginSchema,
        },
        validatorAdapter: zodValidator(),
        onSubmit: async ({ value }) => {
            try {
                if (value.session) {
                    await loginMutation.mutateAsync({
                        otp: value.code,
                        session: value.session,
                        username: value.username,
                        clientId: params.clientid,
                    })
                } else {
                    await loginMutation.mutateAsync({
                        email: value.email,
                        password: value.password,
                        clientId: params.clientid,
                    })
                }

                toast({
                    title: 'Login Successful',
                    description: 'Redirecting to Dashboard',
                    variant: 'success',
                })
                router1.invalidate()
            } catch (error) {
                Sentry.captureException(error.response.data.message, {
                    extra: { mutationKey: 'login' },
                })

                if (error?.status === 301) {
                    if (error?.response?.data?.redirect) {
                        toast({
                            title: 'Password Expired!',
                            description:
                                'Please reset the password using the code sent on your email.',
                            variant: 'warning',
                        })
                        navigate({
                            to: '/$clientid/resetpassword',
                            params,
                            search: {
                                email: value.email,
                            },
                        })
                    } else if (
                        error.response.data.Session &&
                        error.response.data.message ===
                            'MFA authentication Required'
                    ) {
                        form.setFieldValue(
                            'session',
                            error.response.data.Session
                        )
                        form.setFieldValue(
                            'username',
                            error.response.data.username
                        )
                        toast({
                            title: 'MFA authentication required',
                            description:
                                'Please enter the OTP sent to your registered mobile number.',
                            variant: 'info',
                        })
                    }
                } else {
                    toast({
                        title: 'Invalid Request',
                        description:
                            error?.response?.data?.message ?? error?.message,
                        variant: 'error',
                    })
                }
            }
        },
    })

    const emailField = form.useField({
        name: 'email',
    })

    const passwordField = form.useField({
        name: 'password',
    })

    const codeField = form.useField({ name: 'code' })

    return (
        <OnboardingContainer>
            <div className="custom-font-color justify-items-center items-center">
                <h2
                    className="block text-wool md:text-4xl text-3xl font-bold custom-font-color"
                    style={{ color: themeConfig?.visibleTextColor }}
                >
                    {portalConfigData?.TitleBar || ''}
                </h2>
                <h4
                    className="block"
                    style={{ color: themeConfig?.visibleTextColor }}
                >
                    {portalConfigData?.SubTitle || ''}
                </h4>
            </div>

            <Card className="border-none lg:max-w-lg md:max-w-md mt-8 mx-auto p-4 rounded-2xl shadow-md sm:max-w-sm sm:mt-6 sm:p-6 sm:shadow-lg w-[90%] xl:max-w-xl">
                <form>
                    <CardHeader className="md:p-0 p-1">
                        <CardTitle className="font-bold font-sans mb-0 md:mb-0 not-italic text-2xl text-center">
                            Login
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="md:p-1 pb-0 px-1">
                        <CustomInput
                            id={emailField.name}
                            name={emailField.name}
                            value={emailField.state.value}
                            onChange={(e) =>
                                emailField.setValue(e.target.value)
                            }
                            isTouched={emailField.state.meta.isTouched}
                            errors={emailField.state.meta.errors}
                            onBlur={emailField.handleBlur}
                            label={'Email'}
                            type={'email'}
                            disabled={form.state.values.session}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />
                        <CustomInput
                            id={passwordField.name}
                            name={passwordField.name}
                            value={passwordField.state.value}
                            onChange={(e) =>
                                passwordField.setValue(e.target.value)
                            }
                            isTouched={passwordField.state.meta.isTouched}
                            errors={passwordField.state.meta.errors}
                            onBlur={passwordField.handleBlur}
                            label={'Password'}
                            type={'password'}
                            disabled={form.state.values.session}
                            classNames={{
                                root: ['my-4'],
                            }}
                        />
                        {form.state.values.session ? (
                            <CustomInput
                                id={codeField.name}
                                name={codeField.name}
                                value={codeField.state.value}
                                onChange={(e) =>
                                    codeField.setValue(e.target.value)
                                }
                                isTouched={codeField.state.meta.isTouched}
                                errors={codeField.state.meta.errors}
                                onBlur={codeField.handleBlur}
                                label={'Confirmation Code'}
                                type={'text'}
                                maxLength={6}
                                classNames={{
                                    root: ['my-4'],
                                }}
                            />
                        ) : null}
                    </CardContent>
                    <CardFooter className="grid grid-cols-1 pb-3">
                        <span
                            className="cursor cursor-pointer font-bold font-sans justify-self-end md:text-base my-2 sm:my-1 text-[#008FBD] text-right text-sm w-max"
                            onClick={() =>
                                navigate({ to: '/$clientid/forgotpassword' })
                            }
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (['Enter', ' '].includes(e.key)) {
                                    navigate({
                                        to: '/$clientid/forgotpassword',
                                    })
                                }
                            }}
                        >
                            Forgot Password?
                        </span>

                        <PrimaryButton
                            type="submit"
                            data-testid="submit"
                            title="Login"
                            onClick={form.handleSubmit}
                            className="h-6 justify-self-center md:mt-1 md:text-base mt-2 px-12 py-5 text-center text-sm w-fit"
                            isLoading={form.state.isSubmitting}
                            disabled={
                                !(
                                    !form.state.isPristine && form.state.isValid
                                ) || form.state.isSubmitting
                            }
                        />
                        <div className="pt-4">
                            <h4 className="items-center justify-self-center custom-font-color">
                                {portalConfigData?.LoginFooter || ''}
                            </h4>
                        </div>
                    </CardFooter>
                </form>
            </Card>
        </OnboardingContainer>
    )
}

export default Login
