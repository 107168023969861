export const NoClientIdFound = () => {
    return (
        <div className="flex flex-col items-center justify-center w-full space-y-4">
            <img
                src="/svg/no-client-id-found.svg"
                alt=""
                className="max-md:w-[50%] w-[20vw]"
            />
            <div className="max-md:max-w-[100%] text-center">
                <p className="text-xl max-md:text-sm max-md:whitespace-normal">
                    Client ID not found.
                </p>
                <p className="text-xl max-md:text-sm max-md:whitespace-normal">
                    Please add client id in the url or use the url provided
                    during onboarding.
                </p>
            </div>
        </div>
    )
}
