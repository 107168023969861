import { Tabs, TabsList, TabsTrigger } from '@broadlume/willow-ui'
import {
    getRouteApi,
    useNavigate,
    useRouterState,
} from '@tanstack/react-router'

import { useEffect, useMemo } from 'react'
import Header from '../components/header'

import { FaFileAlt, FaShoppingCart } from 'react-icons/fa'
import { FaUserGroup } from 'react-icons/fa6'
import { IoMdSettings } from 'react-icons/io'
import { MdPieChart } from 'react-icons/md'
import { PiSpeedometerFill } from 'react-icons/pi'
import { GlobalModal } from '../components/GlobalModal'
import { getTabs } from '../services/auth'
import { useAuthHook } from '../utils/hooks/auth.hooks'
import { useFetchCustomTheme } from './settings-customHook'
import clsx from 'classnames'

const router = getRouteApi('/$clientid/_app')
const iconMapper = {
    dashboard: <PiSpeedometerFill className={`w-5 h-5 : ''}`} />,
    orders: <FaShoppingCart className={`w-5 h-5 : ''}`} />,
    'order-analysis': <MdPieChart className={`w-5 h-5 : ''}`} />,
    invoices: <FaFileAlt className={`w-5 h-5 : ''}`} />,
    'user-admin': <FaUserGroup className={`w-5 h-5 : ''}`} />,
    settings: <IoMdSettings className={`w-5 h-5 : ''}`} />,
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
    })
}
export const LandingPage = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate()
    const params = router.useParams()
    const routerState = useRouterState()
    const { data: userData } = useAuthHook()
    const search = router.useSearch()
    const yardiSource = useMemo(
        () => search?.source === 'yardi',
        [search?.source]
    )

    // Replace &amp; with & in the query string and redirect
    useEffect(() => {
        const currentUrl = window.location.href
        console.log('currentUrl', currentUrl)

        if (currentUrl.includes('&amp;')) {
            const updatedUrl = currentUrl.replace(/&amp;/g, '&')
            window.location.replace(updatedUrl)
        }
    }, [])

    const tabList = getTabs({ userDetails: userData?.userDetails })

    const currentPath = `/${params.clientid}/`
    const { data: themeConfig } = useFetchCustomTheme()
    const css = `@media (min-width: 640px){
                    button[role="tab"]{
                        color: ${themeConfig?.visibleTextColor};
                    }
                    button[data-state="active"][role="tab"]{
                        color: ${themeConfig?.visibleTextColor};
                    }
                }
                @media not all and (min-width: 640px) {
                    .tab-list {
                        bottom: ${
                            document.getElementsByTagName('footer')?.[0]
                                ?.clientHeight
                        }px;
                    }
                }
                    
                    `
    return (
        <div className="justify-start overflow-x-auto w-full whitespace-nowrap">
            <Header isYardiSource={yardiSource} />
            <style>{css}</style>

            {!yardiSource ? (
                <div className="bg-[transparent] flex justify-center shadow-[0px_8px_16px_0px_#0000000D]">
                    <Tabs
                        className="w-full"
                        value={routerState.location.pathname}
                    >
                        <TabsList
                            className={clsx(
                                `sm:flex sm:flex-row sm:gap-7 overflow-x-auto sm:whitespace-nowrap fixed sm:relative sm:bottom-0 sm:mb-0 sm:left-0 sm:z-10 sm:w-full mb-1 left-0 z-10 w-full sm:max-lg:outline-none sm:overflow-unset box-shadow-[0px_8px_16px_0px_rgba(0,0,0,0.05)] sm:bg-transparent sm:visible-font max-sm:mb-0 max-sm:justify-evenly tab-list max-sm:fixed`
                            )}
                        >
                            {tabList.map((eachTab) => {
                                // Determine if the current tab is active
                                let isActive =
                                    routerState.location.pathname ===
                                    `${currentPath}${eachTab.id}`

                                // making sure that Orders tab is active when the Place Order button is clicked
                                if (
                                    routerState.location.pathname ===
                                        `${currentPath}place-order` &&
                                    `${currentPath}${eachTab.id}` ===
                                        `${currentPath}orders`
                                ) {
                                    isActive = true
                                }

                                return (
                                    eachTab.isTab && (
                                        <TabsTrigger
                                            key={eachTab.id}
                                            id={eachTab.id}
                                            value={`${currentPath}${eachTab.id}`}
                                            onClick={() => {
                                                navigate({
                                                    to: `${currentPath}${eachTab.id}`,
                                                    params,
                                                })
                                                scrollToTop()
                                            }}
                                            className="align-middle border-none flex gap-4 p-3 justify-between max-sm:p-0"
                                        >
                                            {/* Use isActive to control icon rendering */}
                                            {iconMapper?.[eachTab.id]}
                                            <span
                                                className={
                                                    isActive
                                                        ? ''
                                                        : 'hidden lg:block '
                                                }
                                            >
                                                {eachTab.label}
                                            </span>
                                        </TabsTrigger>
                                    )
                                )
                            })}
                        </TabsList>
                    </Tabs>
                </div>
            ) : null}

            <div className="justify-start overflow-x-auto w-full whitespace-nowrap h-fit mb-4">
                {children}
            </div>

            <GlobalModal />
        </div>
    )
}
