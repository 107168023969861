export const CardContainer = ({
    className = '',
    children,
    ...props
}: React.PropsWithChildren<
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
>) => {
    return (
        <div
            className={`flex flex-col flex-1 sm:p-5 p-3 rounded-lg bg-willow-wool-light ${className}`}
            {...props}
        >
            {children}
        </div>
    )
}

export const CardRow = ({
    children,
    ...props
}: React.PropsWithChildren<
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >
>) => {
    return (
        <div className="flex my-2" {...props}>
            {children}
        </div>
    )
}
