import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import {
    useFetchCustomTheme,
    useFetchPortalConfig,
} from '../pages/settings-customHook'
import { Logo } from './logo'

type SocialData = {
    instagram?: string
    plateformX?: string
    facebook?: string
    linkedin?: string
    youtube?: string
}
const SocialIcons = ({ data }: { data: SocialData }) => (
    <div className="flex gap-4 justify-center">
        {data?.instagram && (
            <a
                href={`${data?.instagram}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <AiFillInstagram className="h-6 w-6 text-[hsl(var(--secondary))] hover:text-[hsl(var(--primary))] transition duration-300" />
            </a>
        )}
        {data?.plateformX && (
            <a href={`${data?.plateformX}`} target="_blank">
                <FaXTwitter className="h-6 w-6 text-[hsl(var(--secondary))] hover:text-[hsl(var(--primary))] transition duration-300" />
            </a>
        )}

        {data?.facebook && (
            <a href={`${data?.facebook}`} target="_blank">
                <FaFacebookF className="h-6 w-6 text-[hsl(var(--secondary))] hover:text-[hsl(var(--primary))] transition duration-300" />
            </a>
        )}
        {data?.linkedin && (
            <a href={`${data?.linkedin}`} target="_blank">
                <FaLinkedinIn className="h-6 w-6 text-[hsl(var(--secondary))] hover:text-[hsl(var(--primary))] transition duration-300" />
            </a>
        )}
        {data?.youtube && (
            <a href={`${data?.youtube}`} target="_blank">
                <AiFillYoutube className="h-6 w-6 text-[hsl(var(--secondary))] hover:text-[hsl(var(--primary))] transition duration-300" />
            </a>
        )}
    </div>
)
const Footer = ({
    logoUrl,
    footerProps,
    theming = { title: '' },
}: {
    logoUrl?: string
    footerProps?: React.HTMLAttributes<HTMLElement>
    theming?: { title: string }
}) => {
    const { data } = useFetchCustomTheme()
    const { data: portalConfigData } = useFetchPortalConfig()

    return (
        <footer
            className="fixed bottom-0 left-0 z-10 sm:relative w-full bg-willow-ash py-1  md:px-12 text-willow-wool-light custom-header-footer "
            {...footerProps}
        >
            <div className="sm:grid sm:grid-cols-3 sm:gap-8 sm:items-center sm:text-left text-center gap-4 sm:mr-2 sm:ml-2">
                {/* Left Section: Logo & Info */}
                <div className="hidden sm:block sm:col-span-2">
                    <div className="flex max-w-40 mb-2 mt-2">
                        <Logo
                            key="footer-logo"
                            className="max-h-12 hover:cursor-pointer"
                            logoUrl={logoUrl}
                            onClick={() =>
                                portalConfigData?.LinkHome
                                    ? window.open(portalConfigData.LinkHome)
                                    : null
                            }
                        />
                    </div>
                    <div className="mb-2 opacity-70 text-sm">
                        {portalConfigData?.copyRight}
                    </div>
                    <div className="gap-1 grid lg:grid-cols-4 grid-cols-2">
                        {data?.location && (
                            <div className="md:text-sm text-xs  whitespace-normal break-words">
                                <span
                                    style={{ color: theming?.title }}
                                    className="text-[hsl(var(--primary))]"
                                >
                                    Location:
                                </span>
                                <br />
                                <a
                                    href="#"
                                    className="md:text-sm text-xs whitespace-normal break-words "
                                    style={{ fontWeight: 'normal ' }}
                                >
                                    {data?.location || ''}
                                </a>
                            </div>
                        )}
                        {data?.salescontactnumber && (
                            <div className="md:text-sm text-xs  whitespace-normal break-words">
                                <span
                                    style={{ color: theming?.title }}
                                    className="text-[hsl(var(--primary))]"
                                >
                                    Sales:
                                </span>
                                <br />
                                <a
                                    href="#"
                                    className="md:text-sm text-xs  whitespace-normal break-words"
                                    style={{ fontWeight: 'normal ' }}
                                >
                                    {data?.salescontactnumber || ''}
                                </a>
                            </div>
                        )}
                        {data?.supportEmail && (
                            <div className="md:text-sm text-xs  whitespace-normal break-words">
                                <span
                                    style={{ color: theming?.title }}
                                    className="text-[hsl(var(--primary))]"
                                >
                                    Support:
                                </span>
                                <br />
                                <a
                                    href="#"
                                    className="md:text-sm text-xs  whitespace-normal break-words"
                                    style={{ fontWeight: 'normal ' }}
                                >
                                    {data?.supportEmail || ''}
                                </a>
                            </div>
                        )}
                        {data?.email && (
                            <div className="md:text-sm text-xs  whitespace-normal break-words">
                                <span
                                    style={{ color: theming?.title }}
                                    className="text-[hsl(var(--primary))]"
                                >
                                    More Info:
                                </span>
                                <br />
                                <a
                                    href="#"
                                    className="md:text-sm text-xs  whitespace-normal break-words"
                                    style={{ fontWeight: 'normal ' }}
                                >
                                    {data?.email || ''}
                                </a>
                            </div>
                        )}
                    </div>
                </div>

                {/* Small Screen Stacked Layout */}
                <div className="flex flex-col gap-2 items-center sm:hidden py-2">
                    {/* Copyright */}
                    <div className="opacity-70 text-sm">
                        {portalConfigData?.copyRight}
                    </div>

                    {/* Social Icons */}
                    <SocialIcons data={data} />
                </div>

                {/* Right Section: Social Icons (Larger Screens) */}
                <div className="gap-4 hidden justify-end sm:flex">
                    <SocialIcons data={data} />
                </div>
            </div>
        </footer>
    )
}

export default Footer
