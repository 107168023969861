import { useToast } from '@broadlume/willow-ui'
import { useQueryClient } from '@tanstack/react-query'

export interface ToastProps {
    title: string
    description: string
    variant: 'success' | 'error' | 'info' | 'warning'
    timeout?: number
}

export const useCustomToast = () => {
    const { toast } = useToast()
    const customToast = ({ timeout, ...rest }: ToastProps) => {
        const newToast = toast({ ...rest })
        setTimeout(() => {
            newToast.dismiss()
        }, timeout || 3500)
    }
    return { toast: customToast }
}

export const useModal = () => {
    const queryClient = useQueryClient()

    // Open modal with specific content
    const openModal = ({
        content,
        onClose,
    }: {
        content: React.ReactNode
        onClose: () => void
    }) => {
        queryClient.setQueryData(['global-modal'], {
            isOpen: true,
            content,
            onClose,
        })
    }

    // Close modal
    const closeModal = () => {
        queryClient.setQueryData(['global-modal'], {
            isOpen: false,
            content: null,
        })
    }

    return { openModal, closeModal }
}
