import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { Logo } from '../components/logo'
import {
    useFetchCustomTheme,
    useFetchPortalConfig,
} from './settings-customHook'

const router = getRouteApi('/$clientid')
export const OnboardingContainer = ({ children }: React.PropsWithChildren) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()

    const { data: themeData } = useFetchCustomTheme()
    const { data: portalConfigData } = useFetchPortalConfig()

    return (
        <div className="flex-1 relative flex flex-col items-center justify-center pb-4">
            {['#fff8e6', '', undefined].includes(themeData?.bgColor) && (
                <div className="absolute top-0 left-0 md:block hidden">
                    <img
                        src="/svg/loginUpImg.svg"
                        alt=""
                        className="md:w-[17vw] w-[20vw]"
                    />
                </div>
            )}

            <div className="flex max-w-56 mb-8">
                <Logo
                    className="max-h-48 cursor-pointer"
                    onClick={async () => {
                        portalConfigData?.LinkHome
                            ? window.open(portalConfigData.LinkHome)
                            : await navigate({
                                  to: '/$clientid/login',
                                  params: params,
                              })
                    }}
                    secondaryFill="#000"
                />
            </div>
            {children}
            {['#fff8e6', '', undefined].includes(themeData?.bgColor) && (
                <div className="absolute bottom-0 right-0 md:block hidden">
                    <img
                        src="/svg/loginUpImg.svg"
                        alt=""
                        style={{
                            transform: 'scale(-1, -1)',
                        }}
                        className="md:w-[17vw] w-[20vw]"
                    />
                </div>
            )}
        </div>
    )
}
