import { useMemo } from 'react'
import { useGetCustAddr, useGetCustomer, useGetPordFile } from './podfile.hook'
import { handleGlobalError } from '..'
import { useAuthHook } from './auth.hooks'

export const useGetCustomerPordFile = () => {
    const {
        data: pordFileData,
        isLoading: isPordFileLoading,
        error: pordFileError,
    } = useGetPordFile()
    const { data: userData } = useAuthHook()

    const {
        data: custAddrData,
        isLoading: isCustAddrLoading,
        error: custAddrError,
    } = useGetCustAddr()

    const {
        data: customerData,
        isLoading: isCustomerLoading,
        error: customerError,
    } = useGetCustomer()

    const filteredCustomers = useMemo(() => {
        return customerData
            ?.filter((customer) =>
                pordFileData?.some(
                    (eachPordFile) =>
                        customer.C_CID === eachPordFile.PDMO_CUSTID
                )
            )
            .map((customer) => ({
                ...customer,
                ...custAddrData?.find(
                    (item) => item.CADD_CUST === customer.C_CID
                ),
            }))
    }, [customerData, pordFileData, custAddrData])

    const shipToItems = useMemo(() => {
        if (!filteredCustomers?.length) {
            return []
        }
        return filteredCustomers?.map((customer) => ({
            ...customer,
            id: customer.C_CID,
            value: customer.C_CID ?? '',
            label: `${customer.C_CID} - ${customer.C_NAME} (${customer.C_ADDR1})`,
        }))
    }, [filteredCustomers])

    if (
        !isCustomerLoading &&
        !isPordFileLoading &&
        !isCustAddrLoading &&
        shipToItems.length === 0
    ) {
        console.log('error===', pordFileError)
        handleGlobalError({ error: pordFileError, userData })
    }

    return {
        customerData,
        custAddrData,
        filteredCustomers,
        isCustomerLoading,
        isPordFileLoading,
        isCustAddrLoading,
        pordFileData,
        shipToItems,
        pordFileError,
        custAddrError,
        customerError,
    }
}
