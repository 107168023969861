import { PropsWithChildren, useEffect, useState } from 'react'
import { TreeView, TreeNode } from './tree-view'

export const OrderItems: React.FC<
    PropsWithChildren & {
        materials: TreeNode
        labors: TreeNode
        onItemsSelected: (values: string[]) => void
        setSelectedNodes: (nodes: TreeNode[]) => void
        selected?: string[]
    }
> = ({
    materials,
    labors,
    onItemsSelected,
    setSelectedNodes,
    selected = [],
}) => {
    const childrenNodes = [...(materials?.children || [])]
    const [selectedIds, setSelectedIds] = useState<string[]>([])

    if (labors?.children?.length > 0) {
        childrenNodes.push(labors)
    }

    useEffect(() => {
        onItemsSelected(selectedIds)
    }, [selectedIds, onItemsSelected])

    useEffect(() => {
        typeof selected === 'object' &&
            JSON.stringify(selected) !== JSON.stringify(selectedIds) &&
            handleTreeChange(selected)
    }, [selected])

    const handleTreeChange = (selectedValues: string[]) => {
        setSelectedIds(selectedValues)
    }

    return (
        <div className="p-4">
            <TreeView
                data={childrenNodes}
                onChange={handleTreeChange}
                selectedValues={selectedIds} // Pass selected values down
                setSelectedNodes={setSelectedNodes}
            />
        </div>
    )
}
