import { queryClient } from '../../context/constants'
import { authQueryFn } from '../../services/auth'
import { useSentryQuery } from '../useSentryQuery'

export const useGetClient = () => {
    return useSentryQuery({
        queryKey: ['clientId'],
        queryFn: () => {
            const clientId = queryClient.getQueryData<string>(['clientId'])
            if (!clientId) {
                throw new Error('Client ID not found in query cache')
            }
            return clientId
        },
    })
}

export const useAuthHook = () => {
    const { data: clientId } = useGetClient()

    return useSentryQuery({
        queryKey: ['authKeys', clientId],
        queryFn: () => authQueryFn(),
    })
}
