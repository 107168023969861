import {
    Label,
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@broadlume/willow-ui'
type SelectWrapperProps = {
    data: { id: string; label: string; value: string }[]
    placeholder?: string
    label?: string
    required?: boolean
    classNames?: {
        root?: string[]
        label?: string[]
    }
    otherProps?: {
        selectRootProps?: Parameters<typeof Select>[0]
        selectTriggerProps?: Parameters<typeof SelectTrigger>[0]
        selectContentProps?: Parameters<typeof SelectContent>[0]
        selectItemProps?: Omit<Parameters<typeof SelectItem>[0], 'value'>
        labelProps?: Parameters<typeof Label>[0]
    }
}
export const SelectWrapper = ({
    data = [],
    classNames = {
        root: [],
        label: [],
    },
    placeholder = 'Select an option',
    label,
    required = false,
    otherProps = {
        selectRootProps: {},
        selectContentProps: {},
        selectTriggerProps: {},
        selectItemProps: {},
        labelProps: {},
    },
}: SelectWrapperProps) => {
    return (
        <div className={`${classNames.root}`} id={`item-${label}`}>
            {label && (
                <Label
                    className={`mb-2 ${classNames.label}`}
                    {...otherProps.labelProps}
                >
                    {label}{' '}
                    {required && <span className="text-willow-danger">*</span>}{' '}
                </Label>
            )}
            <Select {...otherProps.selectRootProps}>
                <SelectTrigger
                    // className="~h-auto" //TODO:  Add the Custom style tag to fix the height
                    {...otherProps.selectTriggerProps}
                    aria-placeholder={placeholder}
                >
                    <SelectValue id={placeholder} placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent {...otherProps.selectContentProps}>
                    {data.map((eachItem) => (
                        <SelectItem
                            id={eachItem.value}
                            key={`key-${eachItem.value}`}
                            value={eachItem.value}
                            {...otherProps.selectItemProps}
                        >
                            {eachItem.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    )
}
