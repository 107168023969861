import { useFetchCustomTheme } from '../pages/settings-customHook'

export const ServerError = () => {
    const { data: themeConfig } = useFetchCustomTheme()
    return (
        <div className="flex flex-col p-40 max-md:px-5 max-md:mt-20 max-md:py-5 gap-20 items-center justify-center h-full">
            <img
                src="/svg/no-property-found.svg"
                alt=""
                className="max-md:w-[50%] w-[20vw]"
            />
            <div className="max-md:max-w-[100%] text-center">
                <p
                    className={`text-xl max-md:text-sm max-md:whitespace-normal`}
                    style={{ color: themeConfig?.visibleTextColor }}
                >
                    Something went wrong
                </p>
                <p
                    className={`text-xl max-md:text-sm max-md:whitespace-normal`}
                    style={{ color: themeConfig?.visibleTextColor }}
                >
                    Server is not reachable at the moment. Please try again
                    later
                </p>
            </div>
        </div>
    )
}
