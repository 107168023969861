import {
    Label,
    Input,
    InputProps,
    TextareaProps,
    Textarea,
} from '@broadlume/willow-ui'
export const InputField = ({
    id,
    label,
    labelClassName,
    fieldType,
    required = false,
    ...rest
}: {
    id: string
    label?: string
    labelClassName?: string
    required?: boolean
    fieldType?: string
} & InputProps &
    TextareaProps) => {
    return (
        <div>
            {label && (
                <Label className={labelClassName} htmlFor={id}>
                    <span>{label}</span>
                    {required && <span className="text-willow-danger">*</span>}
                </Label>
            )}
            {fieldType === 'textarea' && <Textarea id={id} {...rest} />}
            {!fieldType && <Input id={id} {...rest} />}
        </div>
    )
}
