import { useSentryQuery } from '../utils/useSentryQuery'
import { Modal } from './modal'

export const GlobalModal = () => {
    const { data } = useSentryQuery({
        queryKey: ['global-modal'],
        queryFn: () => ({ isOpen: false, content: null, onClose: () => {} }), // Default state
        staleTime: Infinity,
    })

    if (!data?.isOpen || !data.content) return null

    return (
        <Modal
            content={data.content}
            open={data.isOpen}
            toggleModal={data.onClose}
            modalContentClasses="bg-[#F9FAFC] min-w-full sm:min-w-[80vw] max-h-[80vh] order-details overflow-y-auto !p-0"
        />
    )
}
