import { useMutation } from '@tanstack/react-query'
import { axiosInstance } from '../../../services/api'
import { useAuthHook } from '../../../utils/hooks/auth.hooks'
import { useSentryQuery } from '../../../utils/useSentryQuery'
import { Unpacked } from '../../dashboard/types'
import { useFetchPortalConfig } from '../../settings-customHook'

type EdocType = {
    COBR: string
    SEQ: string
    BASENAME: string
    DATE: string
    TIME: string
    FILEEXT: string
    DESC: string
    TYPE: string
}
export const useGetAllEdocs = ({
    // customer = 'SPRI5002',
    // property = '1X1 608SF 1ST FLR ABBR',
    // shipTo = '0000',
    customer,
    property,
    shipTo,
}: {
    customer: string
    property: string
    shipTo: string
}) => {
    const { data: { userDetails } = {} } = useAuthHook()
    /**
     * Sample Data
     * {
		"COBR": "1_5_",
		"SEQ": "0001",
		"BASENAME": "SPRI 5002 -SPRINGBROOK APARTMENTS - 1x1 - 1st FLOOR  (608SQF) ABBR INSTALLER DIAGRAM.pdf",
		"DATE": "20230203",
		"TIME": "10255927",
		"FILEEXT": "pdf  ",
		"DESC": "SPRI 5002 -SPRINGBROOK APARTMENTS - 1x1 - 1st FLOOR  (608SQF",
		"TYPE": "NYNNNNNNNNNNNNN"
	}
     */

    const { data: portalConfigData } = useFetchPortalConfig()
    const edocindex = portalConfigData?.edocindex || null

    // Find correct sequence here.
    // Fetch config and set to variable.
    // const edocindex = 5
    return useSentryQuery<EdocType[]>({
        queryKey: ['getAllEdocs', userDetails, customer, property, shipTo],
        enabled:
            Boolean(edocindex) &&
            Boolean(userDetails) &&
            Boolean(customer) &&
            Boolean(property) &&
            Boolean(shipTo),
        queryFn: async ({ signal }) => {
            const response = await axiosInstance.get(`/api/edocs`, {
                params: {
                    property,
                    edoctype: 'property',
                    shcode: shipTo,
                    customer: customer,
                    // company: '1',
                    // branch: '5',
                    alias: userDetails?.api_config,
                    branch: userDetails?.Branch,
                    company: userDetails?.Company,
                },
                signal,
            })

            return (
                response.data as {
                    COBR: string
                    SEQ: string
                    BASENAME: string
                    DATE: string
                    TIME: string
                    FILEEXT: string
                    DESC: string
                    TYPE: string
                }[]
            ).filter((item) => item.TYPE[edocindex - 1] === 'Y')
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchInterval: 1000 * 60 * 5, // 5 minutes
    })
}

export const useGetEdocPdf = ({
    // customer = 'SPRI5002',
    // property = '1X1 608SF 1ST FLR ABBR',
    // shipTo = '0000',
    customer,
    property,
    shipTo,
    BASENAME,
    SEQ,
}: Parameters<typeof useGetAllEdocs>['0'] &
    Unpacked<ReturnType<typeof useGetAllEdocs>['data']>) => {
    const { data: { userDetails } = {} } = useAuthHook()
    console.log('SEQ', SEQ, BASENAME)
    return useSentryQuery({
        queryKey: ['getEdocPdf', userDetails?.id, SEQ, BASENAME],
        enabled:
            Boolean(customer) &&
            Boolean(property) &&
            Boolean(shipTo) &&
            Boolean(SEQ),
        queryFn: async ({ signal }) => {
            const response = await axiosInstance.get('/api/edoc', {
                params: {
                    property,
                    edoctype: 'property',
                    shcode: shipTo,
                    customer: customer,
                    // company: '1',
                    // branch: '5',
                    alias: userDetails?.api_config,
                    seq: SEQ,
                    branch: userDetails?.Branch,
                    company: userDetails?.Company,
                },
                signal,
                // headers: {
                //     token: '90ad8ce7ab9d3d12b7b75968c455ffbd',
                //     'x-api-key': '6q5EVranOvbPsEBt7qwmc66mVV4n11ZzZyK2YfMZ',
                // },
            })

            return response
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchInterval: 1000 * 60 * 5, // 5 minutes
    })
}

type EmailConfig =
    | {
          emailType: 'place-order'
          title: string
          heading: string
          customerName: string
          referenceNumber: string
          shipToAddress: string
          building: string
          unit: string
          placedBy: string
          email: string
          unitType: string
          poNumber: string
          installDate: string
          moveInDate: string
          customerServicePhone: string
          lines: string[]
      }
    | {
          emailType: 'add-user'
          title: string
          heading: string
          email: string
      }

export const useSendEmail = () => {
    return useMutation({
        mutationKey: ['sendEmail'],
        mutationFn: async (data: EmailConfig) => {
            return axiosInstance
                .post('/triggerEmail', data, {
                    headers: {
                        'x-cache-hit': 'false',
                    },
                })
                .then((resp) => resp.data)
        },
    })
}

export const useGetSendToAddress = ({
    customer,
    shipTo,
}: {
    customer: string
    shipTo: string
}) => {
    const { data: { userDetails } = {} } = useAuthHook()
    return useSentryQuery({
        queryKey: ['getshiptoaddr', userDetails?.id],
        enabled: Boolean(customer) && Boolean(shipTo),
        queryFn: async ({ signal }) => {
            const response = await axiosInstance.get(`/api/testdebug`, {
                params: {
                    alias: userDetails?.api_config,
                    branch: userDetails?.Branch,
                    company: userDetails?.Company,
                    function: 'getshiptoaddr',
                    customer: customer,
                    shipto: shipTo,
                    properties: userDetails?.Properties,
                },
                signal,
            })

            return response.data
        },
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchInterval: 1000 * 60 * 5, // 5 minutes
    })
}
