import {
    CognitoIdentityProviderClient,
    InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import devConfig from '../config.json'
import prodConfig from '../prod-config.json'
import axios, { AxiosRequestConfig } from 'axios'
import { CognitoJwtVerifier } from 'aws-jwt-verify'
import { setAndVerifyToken } from './auth'
import { queryClient } from '../context/constants'
import * as Sentry from '@sentry/react'
const config = ['https://customerportal.bms.my.broadlume.com'].includes(
    window.location.origin
)
    ? prodConfig
    : devConfig
export const cognitoClient = new CognitoIdentityProviderClient({
    region: config.region,
})

export const verifier = CognitoJwtVerifier.create({
    userPoolId: config.userPoolId,
    clientId: config.clientId,
    tokenUse: 'access',
})

export const BaseUrl = ['https://customerportal.bms.my.broadlume.com'].includes(
    window.location.origin
)
    ? 'https://api.bms.my.broadlume.com'
    : 'https://dev.api.bms.my.broadlume.com'

export const axiosInstance = axios.create({
    baseURL: BaseUrl, // Set default base URL
    headers: {
        'Content-Type': 'application/json',
    },
    fetchOptions: { cache: 'no-cache' },
})

let isRefreshing = false // To prevent multiple refresh requests
const tokenExpiredQueue: AxiosRequestConfig[] = []
let accessToken: string = ''

const requestQueue: AxiosRequestConfig[] = []
let isRetrying = false

const processQueue = async ({
    queue,
    accessToken,
}: {
    queue: typeof requestQueue
    accessToken?: string
}) => {
    while (queue.length > 0) {
        let requestConfig = queue.shift()
        if (requestConfig) {
            try {
                if (accessToken) {
                    requestConfig = {
                        ...requestConfig,
                        headers: {
                            ...requestConfig.headers,
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                    await axios(requestConfig)
                }
            } catch (error) {
                console.error('Request failed:', error)
            }
        }
    }
}

axiosInstance.interceptors.request.use((request) => {
    if (!request.headers.Authorization) {
        const accessToken = localStorage.getItem('accessToken')
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    return request
})

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data?.ERROR === ' NOT ENOUGH DATA') {
            throw response
        }
        return response
    }, // Directly return successful responses.
    async (error) => {
        console.log('error.response.status', error?.response?.status)
        if (error?.code === 'ERR_CANCELED') {
            throw error
        }
        Sentry.captureException(
            error?.response?.data?.message || error?.response?.data?.ERROR,
            {
                extra: {
                    response: error?.response,
                    error: error,
                },
            }
        )
        if (
            error?.response?.status === 401 &&
            error?.response?.data?.ERROR === 'INVALID APPLICATION TOKEN'
        ) {
            // queue all requests and retry only first with the x-cache-hit false header passed.
            requestQueue.push(error.config)

            if (!isRetrying) {
                isRetrying = true
                try {
                    const firstRequestConfig = requestQueue.shift()
                    if (firstRequestConfig) {
                        firstRequestConfig.headers['x-cache-hit'] = 'false'
                        await axios(firstRequestConfig)
                    }
                    await processQueue({ queue: requestQueue })
                } catch (retryError) {
                    console.error('Retry failed:', retryError)
                } finally {
                    isRetrying = false
                }
            }
        } else if (
            error?.response?.status === 403 &&
            error?.response?.data?.message.includes('Access Token Expired')
        ) {
            // queue all requests and retry only first with the x-cache-hit false header passed.
            tokenExpiredQueue.push(error.config)

            if (!isRefreshing) {
                isRefreshing = true

                try {
                    // regenerate access token.
                    const refreshToken = localStorage.getItem('refreshToken')
                    // Initiate the Refresh Token Auth flow
                    const command = new InitiateAuthCommand({
                        AuthFlow: 'REFRESH_TOKEN_AUTH', // Use Refresh Token Auth flow
                        ClientId: config.clientId, // Replace with your Cognito App Client ID
                        AuthParameters: {
                            REFRESH_TOKEN: refreshToken!, // Provide the refresh token
                        },
                    })

                    const response = await cognitoClient.send(command)
                    console.log('token refreshed', response)
                    if (
                        response.$metadata.httpStatusCode === 200 &&
                        response.AuthenticationResult?.AccessToken &&
                        response.AuthenticationResult.IdToken
                    ) {
                        await setAndVerifyToken({
                            IdToken: response.AuthenticationResult.IdToken,
                            AccessToken:
                                response.AuthenticationResult.AccessToken,
                            RefreshToken: refreshToken!,
                        })
                        accessToken = response.AuthenticationResult.AccessToken
                        await processQueue({
                            queue: tokenExpiredQueue,
                            accessToken,
                        })
                        return
                    }
                    throw response
                } catch (retryError) {
                    console.error('Retry failed:', retryError)
                    localStorage.clear()
                    queryClient.resetQueries()
                    window.location.reload()
                    return Promise.reject(error)
                } finally {
                    isRefreshing = false
                }
            }
        } else {
            return Promise.reject(error)
        }
    }
)
