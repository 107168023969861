import { Label } from '@broadlume/willow-ui'
import clsx from 'classnames'
import Select from 'react-select'
import { ErrorComponent } from './ErrorComponent'
import { CardRow } from './CardContainer'
/**
 * CustomDropDown is a React functional component that renders a styled select input
 * with an associated label and error message component. It utilizes the `react-select`
 * library for the dropdown functionality and customizes the theme and styles.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The text to display as the label for the select input.
 * @param {Object} props.errorProps - The properties to pass to the ErrorComponent for error handling.
 * @param {Object} [props.classNames] - Optional class names for styling, specifically for the label.
 * @param {string[]} [props.classNames.label] - Optional array of class names for the label element.
 * @returns {JSX.Element} A JSX element containing a label, a styled select input, and an error message component.
 */
export const CustomDropDown = ({
    label,
    errorProps,
    ...rest
}: Parameters<typeof Select>[0] & {
    label: string
    errorProps?: Parameters<typeof ErrorComponent>[0]
    classNames?: { label?: string[]; root?: string[] }
}) => {
    const handleChange: Parameters<typeof Select>[0]['onChange'] = (
        selectedOption,
        actionMeta
    ) => {
        if (
            selectedOption?.length &&
            rest.isMulti &&
            selectedOption?.some((option) => option?.value?.includes('all'))
        ) {
            // All options selected.
            return rest?.onChange?.(rest?.options, actionMeta)
        }
        // Non Multi Selection. Send it as it is.
        return rest?.onChange?.(selectedOption, actionMeta)
    }

    return (
        <CardRow
            className={clsx('flex flex-col w-full', rest?.classNames?.root)}
        >
            <Label className={clsx('mb-2', rest?.classNames?.label)}>
                {label}
                {rest.required && <span className="text-willow-danger">*</span>}
            </Label>
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'rgba(var(--primaryRgb), 0.25)',
                        primary: 'hsl(var(--primary))',
                    },
                })}
                // defaultMenuIsOpen={true}
                noOptionsMessage={({ inputValue }) =>
                    inputValue || !rest.options?.length
                        ? 'No Options Found'
                        : 'All options selected'
                }
                aria-errormessage={`${label}-select-error`}
                menuPortalTarget={document.body}
                {...rest}
                styles={{
                    menu: (base) => ({
                        ...base,
                        ...(rest.styles?.menu || {}),
                        zIndex: 9999,
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        ...(rest.styles?.menuPortal || {}),
                        zIndex: 9999,
                    }),
                    control: (base) => ({
                        ...base,
                        ...(rest.styles?.control || {}),
                        borderRadius: '6px',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                        maxHeight: '100px',
                        overflow: 'auto',
                    }),
                    option: (base) => ({
                        ...base,
                        ...(rest.styles?.option || {}),
                        textWrap: 'auto',
                    }),
                }}
                onChange={handleChange}
            />
            <ErrorComponent {...errorProps} />
        </CardRow>
    )
}
