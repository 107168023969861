import {
    useQuery,
    UseQueryOptions,
    QueryFunction,
    QueryKey,
} from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

/**
 * Wrapper around useQuery that captures errors using Sentry.
 *
 * @param options - Object containing queryKey, queryFn, and other React Query options.
 * @returns useQuery result with Sentry error tracking.
 */

export function useSentryQuery<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
        queryKey: TQueryKey
        queryFn: QueryFunction<TQueryFnData, TQueryKey>
        onError?: (error: TError) => void
    } & {
        onError?: (error: TError) => void
    }
) {
    const queryResult = useQuery({
        ...(options as UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>),
    })

    if (queryResult.error) {
        Sentry.captureException(queryResult.error, {
            extra: { queryKey: options.queryKey, queryResult },
        })

        // Call user-defined onError if provided
        if (options.onError) {
            options.onError(queryResult.error as TError)
        }
    }
    return queryResult
}

export type AbortController = Parameters<
    Parameters<typeof useSentryQuery>[0]['queryFn']
>[0]['signal']
