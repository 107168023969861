import { createFileRoute, redirect } from '@tanstack/react-router'
import { authQueryFn, getTabs } from '../../services/auth'
import * as Sentry from '@sentry/react'

export const Route = createFileRoute('/$clientid/')({
    beforeLoad: async ({ params, location, context }) => {
        // We will add authentication here later
        // Route users to default broadlume page in case of missing client id.
        console.log('beforeload params.clientid', params, location)

        if (!params?.clientid) {
            throw redirect({
                to: '/404',
            })
        }
        params.clientid = params.clientid.toLowerCase()
        const { queryClient } = context
        const data = await queryClient.fetchQuery({
            queryKey: ['authKeys', params.clientid],
            queryFn: () => authQueryFn(true),
        })
        await queryClient.setQueryData(['clientId'], params.clientid)
        if (location.search?.source && location.search.source === 'yardi') {
            throw redirect({
                to: '/$clientid/place-order',
                params,
                search: location.search,
            })
        }
        if (data?.userDetails) {
            const tabList = getTabs({ userDetails: data?.userDetails })
            if (!tabList.length) {
                if (!data?.userDetails?.Properties?.length) {
                    Sentry.captureException('No Properties found for user.', {
                        extra: {
                            userDetails: JSON.stringify(data?.userDetails),
                        },
                    })
                }

                if (!data?.userDetails?.access?.length) {
                    Sentry.captureException('No Page access found for user.', {
                        extra: {
                            userDetails: JSON.stringify(data?.userDetails),
                        },
                    })
                }

                throw redirect({
                    to: '/$clientid/401',
                    params,
                })
            }
            throw redirect({
                to: '/$clientid/' + tabList[0].id,
                params,
            })
        }
        throw redirect({
            to: '/$clientid/login',
            params,
        })
    },
})
