import { Dialog, DialogContent } from '@broadlume/willow-ui'
import clsx from 'classnames'

export interface ModalOptions {
    open: boolean
    content: string | JSX.Element
    toggleModal: () => void
    modalContentClasses: string
}

export const Modal = ({
    open,
    content,
    toggleModal,
    modalContentClasses,
}: ModalOptions) => {
    return (
        <Dialog key={'global-dialog'} open={open}>
            <DialogContent
                onClose={toggleModal}
                className={clsx('bg-white', modalContentClasses)}
            >
                {content}
            </DialogContent>
        </Dialog>
    )
}
