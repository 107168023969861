import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { createRouter, RouterProvider } from '@tanstack/react-router'
import { routeTree } from '../routeTree.gen'
import { queryClient } from './constants'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
const SENTRY_DSN =
    window.location.origin === 'http://localhost:5173'
        ? undefined
        : 'https://d7369b95643ef4e463fd09bdc4ab946d@sentry.broadlume.com/31'
// Set up a Router instance
const router = createRouter({
    routeTree,
    context: {
        queryClient,
    },
    defaultPreload: 'intent',
    // Since we're using React Query, we don't want loader calls to ever be stale
    // This will ensure that the loader is always called when the route is preloaded or visited
    defaultPreloadStaleTime: 0,
})

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
        Sentry.tanstackRouterBrowserTracingIntegration(router),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment:
        window.location.origin === 'http://localhost:5173'
            ? 'local'
            : window.location.origin ===
                'https://dev.customerportal.bms.my.broadlume.com'
              ? 'development'
              : 'production',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

// Register things for typesafety
declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}
export const Provider = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            {window.location.origin === 'http://localhost:5173' && (
                <ReactQueryDevtools initialIsOpen={false} />
            )}
        </QueryClientProvider>
    )
}
